/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import styled from "styled-components";
import ModuleCard from "./ModuleCard";
import DeliveryFormat from "./DeliveryFormat";

function CourseCurriculum({type,course}) {

  const [modules,setModules] = useState({
    'frontend' : {
      'react' : {
        "module_1": {
          "title": "Introduction to ReactJS",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with React",
              "content": [
                "Introduction to React: What and Why?",
                "Setting up a React development environment",
                "Understanding the React component-based architecture",
                "Creating your first React app using Create React App",
                "Hands-on exercise: Building a simple 'Hello World' app."
              ]
            },
            {
              "title": "Session 1.2: JSX and Rendering",
              "content": [
                "Understanding JSX syntax",
                "Rendering React elements to the DOM",
                "Using props to pass data to components",
                "Hands-on exercise: Creating a component that displays user data."
              ]
            },
            {
              "title": "Session 1.3: Components and Props",
              "content": [
                "Understanding functional and class components",
                "Passing and validating props",
                "Component lifecycle methods (for class components)",
                "Hands-on exercise: Building a dynamic list of items with props."
              ]
            }
          ]
        },
        "module_2": {
          "title": "State Management and Events",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 2.1: Managing State in React",
              "content": [
                "Introduction to state and state management",
                "Using the useState hook for functional components",
                "Understanding state updates and re-rendering",
                "Hands-on exercise: Implementing a counter component."
              ]
            },
            {
              "title": "Session 2.2: Handling Events in React",
              "content": [
                "Handling user input and events (e.g., click, change)",
                "Binding event handlers and passing parameters",
                "Form handling and validation",
                "Hands-on exercise: Creating a simple form with validation."
              ]
            },
            {
              "title": "Session 2.3: Conditional Rendering and Lists",
              "content": [
                "Rendering components conditionally",
                "Using lists and keys to render dynamic content",
                "Handling rendering based on state and props",
                "Hands-on exercise: Building a todo list with add and delete functionality."
              ]
            }
          ]
        },
        "module_3": {
          "title": "Advanced React Concepts",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 3.1: React Hooks",
              "content": [
                "Introduction to React Hooks",
                "Using useEffect for side effects",
                "Custom hooks and their use cases",
                "Hands-on exercise: Implementing a custom hook for fetching data."
              ]
            },
            {
              "title": "Session 3.2: Context API for State Management",
              "content": [
                "Introduction to Context API",
                "Creating and using context providers and consumers",
                "Using useContext hook for managing global state",
                "Hands-on exercise: Building a theme switcher with Context API."
              ]
            },
            {
              "title": "Session 3.3: React Router",
              "content": [
                "Introduction to React Router for routing",
                "Setting up routes and nested routes",
                "Navigating programmatically and using route parameters",
                "Hands-on exercise: Creating a multi-page application with navigation."
              ]
            }
          ]
        },
        "module_4": {
          "title": "Performance Optimization and Testing",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 4.1: Performance Optimization",
              "content": [
                "Understanding React performance issues",
                "Techniques for optimizing React performance (e.g., memoization, lazy loading)",
                "Profiling and debugging performance bottlenecks",
                "Hands-on exercise: Optimizing a performance-critical React application."
              ]
            },
            {
              "title": "Session 4.2: Testing React Components",
              "content": [
                "Introduction to testing libraries (e.g., Jest, React Testing Library)",
                "Writing unit tests for React components",
                "Testing asynchronous actions and user interactions",
                "Hands-on exercise: Writing tests for a React component."
              ]
            },
            {
              "title": "Session 4.3: Integrating with External APIs",
              "content": [
                "Fetching data from APIs using fetch or axios",
                "Handling asynchronous operations with async/await",
                "Updating state based on API responses",
                "Hands-on exercise: Building a weather application using an external API."
              ]
            }
          ]
        },
        "module_5": {
          "title": "Project Development and Best Practices",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 5.1: Building a React Project (Part 1)",
              "content": [
                "Planning and structuring a React project",
                "Setting up project environment and tools",
                "Implementing core features (Part 1)",
                "Hands-on exercise: Start building a comprehensive React application."
              ]
            },
            {
              "title": "Session 5.2: Building a React Project (Part 2)",
              "content": [
                "Completing the project implementation",
                "Performing testing and debugging",
                "Ensuring code quality and best practices",
                "Hands-on exercise: Finalizing and polishing the React project."
              ]
            },
            {
              "title": "Session 5.3: Deployment and Wrap-Up",
              "content": [
                "Preparing React applications for deployment",
                "Deploying to hosting services (e.g., Vercel, Netlify)",
                "Course review and next steps in React development",
                "Hands-on exercise: Deploying your final project."
              ]
            }
          ]
        }
      },
      'javascript' : {
        "module_1": {
          "title": "Introduction to JavaScript",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with JavaScript",
              "content": [
                "What is JavaScript and its history?",
                "JavaScript in the Browser vs. Node.js",
                "Setting up a development environment",
                "Writing your first JavaScript code",
                "Hands-on exercise: Creating a simple 'Hello, World!' script"
              ]
            },
            {
              "title": "Session 1.2: Basic Syntax and Operators",
              "content": [
                "JavaScript syntax: Variables, Data Types, and Operators",
                "Understanding expressions and statements",
                "Basic arithmetic, comparison, and logical operators",
                "Hands-on exercise: Simple calculator using JavaScript"
              ]
            },
            {
              "title": "Session 1.3: Working with Strings and Numbers",
              "content": [
                "String manipulation methods",
                "Number methods and type conversions",
                "Template literals and string interpolation",
                "Hands-on exercise: Building a string formatter tool"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Control Structures and Functions",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 2.1: Control Flow",
              "content": [
                "Conditional statements: if, else, switch",
                "Loops: for, while, do-while",
                "Hands-on exercise: Building a number guessing game"
              ]
            },
            {
              "title": "Session 2.2: Functions in JavaScript",
              "content": [
                "Function declarations and expressions",
                "Parameters, return values, and scope",
                "Arrow functions and anonymous functions",
                "Hands-on exercise: Building a simple task manager with functions"
              ]
            },
            {
              "title": "Session 2.3: Error Handling and Debugging",
              "content": [
                "Understanding and using try-catch blocks",
                "Throwing and handling custom errors",
                "Debugging techniques and tools",
                "Hands-on exercise: Implementing error handling in the task manager"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Objects, Arrays, and Collections",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 3.1: Working with Objects",
              "content": [
                "Creating and using JavaScript objects",
                "Object methods and the this keyword",
                "Object-oriented programming basics: Constructors and Prototypes",
                "Hands-on exercise: Creating a user profile object with methods"
              ]
            },
            {
              "title": "Session 3.2: Arrays and Array Methods",
              "content": [
                "Introduction to arrays and array operations",
                "Common array methods: map, filter, reduce, etc.",
                "Iterating over arrays with loops and methods",
                "Hands-on exercise: Implementing a shopping cart using arrays"
              ]
            },
            {
              "title": "Session 3.3: Working with Collections",
              "content": [
                "Sets and Maps in JavaScript",
                "WeakSets and WeakMaps",
                "Iterating over collections",
                "Hands-on exercise: Building a contact manager using Maps and Sets"
              ]
            }
          ]
        },
        "module_4": {
          "title": "DOM Manipulation and Events",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 4.1: Introduction to the DOM",
              "content": [
                "Understanding the Document Object Model (DOM)",
                "Selecting and manipulating DOM elements",
                "Traversing the DOM tree",
                "Hands-on exercise: Creating a dynamic to-do list"
              ]
            },
            {
              "title": "Session 4.2: Event Handling in JavaScript",
              "content": [
                "Understanding events and event listeners",
                "Common events: click, submit, mouseover, etc.",
                "Event delegation and propagation",
                "Hands-on exercise: Adding interactivity to the to-do list with events"
              ]
            },
            {
              "title": "Session 4.3: Forms and Input Validation",
              "content": [
                "Working with forms and input elements",
                "Client-side form validation",
                "Preventing default form behaviors",
                "Hands-on exercise: Implementing a registration form with validation"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Advanced JavaScript Concepts",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 5.1: Asynchronous JavaScript",
              "content": [
                "Introduction to asynchronous programming",
                "Callbacks, Promises, and async/await",
                "Handling asynchronous operations",
                "Hands-on exercise: Building a weather application using an external API"
              ]
            },
            {
              "title": "Session 5.2: JavaScript Modules and Imports",
              "content": [
                "Understanding modules in JavaScript (ES6+)",
                "Importing and exporting functions, objects, and classes",
                "Organizing code with modules",
                "Hands-on exercise: Refactoring the weather application using modules"
              ]
            },
            {
              "title": "Session 5.3: JavaScript and the Browser API",
              "content": [
                "Introduction to the Browser API: Fetch, Local Storage, and more",
                "Manipulating the DOM with the Browser API",
                "Handling HTTP requests with Fetch API",
                "Hands-on exercise: Enhancing the weather application with local storage"
              ]
            }
          ]
        },
        "module_6": {
          "title": "JavaScript Best Practices and Project",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 6.1: JavaScript Best Practices",
              "content": [
                "Writing clean and efficient JavaScript code",
                "Avoiding common pitfalls and anti-patterns",
                "Code optimization techniques",
                "Hands-on exercise: Refactoring code using best practices"
              ]
            },
            {
              "title": "Session 6.2: Building a JavaScript Project (Part 1)",
              "content": [
                "Planning and structuring a JavaScript project",
                "Setting up the project environment",
                "Implementing core features (Part 1)",
                "Hands-on exercise: Start building a comprehensive web application using JavaScript"
              ]
            },
            {
              "title": "Session 6.3: Building a JavaScript Project (Part 2)",
              "content": [
                "Completing the project implementation",
                "Testing, debugging, and final review",
                "Deploying the JavaScript project",
                "Hands-on exercise: Complete and present the final project"
              ]
            }
          ]
        }
    },
    'jquery' : {
      "module_1": {
        "title": "Introduction to jQuery",
        "topics": 3,
        "expanded": true,
        "sections": [
          {
            "title": "Session 1.1: Getting Started with jQuery",
            "content": [
              "Introduction to jQuery: What and Why?",
              "Including jQuery in your project",
              "Basic syntax and selectors",
              "Setting up a development environment",
              "Hands-on exercise: Creating a simple webpage using jQuery selectors."
            ]
          },
          {
            "title": "Session 1.2: jQuery and the DOM",
            "content": [
              "Understanding the Document Object Model (DOM)",
              "Selecting and manipulating DOM elements",
              "Chaining methods",
              "Handling events with jQuery",
              "Hands-on exercise: Building a dynamic to-do list."
            ]
          },
          {
            "title": "Session 1.3: jQuery Events",
            "content": [
              "Understanding jQuery event methods",
              "Common event methods: .click(), .hover(), .focus(), etc.",
              "Event delegation",
              "Preventing default behaviors and stopping propagation",
              "Hands-on exercise: Implementing a dynamic form with validation."
            ]
          }
        ]
      },
      "module_2": {
        "title": "Enhancing Web Pages with jQuery",
        "topics": 3,
        "expanded": true,
        "sections": [
          {
            "title": "Session 2.1: jQuery Effects",
            "content": [
              "Animations in jQuery: Show, hide, toggle, fade, slide",
              "Custom animations with .animate()",
              "Stopping and chaining animations",
              "Hands-on exercise: Creating an animated image gallery."
            ]
          },
          {
            "title": "Session 2.2: Working with jQuery AJAX",
            "content": [
              "Introduction to AJAX and its benefits",
              "Loading data with $.get(), $.post(), and $.ajax()",
              "Handling JSON data",
              "Error handling in AJAX requests",
              "Hands-on exercise: Building a live search feature using AJAX."
            ]
          },
          {
            "title": "Session 2.3: Manipulating HTML and CSS with jQuery",
            "content": [
              "Changing content dynamically",
              "Modifying attributes and CSS properties",
              "Adding and removing classes",
              "Hands-on exercise: Creating a responsive navigation menu."
            ]
          }
        ]
      },
      "module_3": {
        "title": "Advanced jQuery Techniques",
        "topics": 3,
        "expanded": true,
        "sections": [
          {
            "title": "Session 3.1: Traversing the DOM",
            "content": [
              "Understanding jQuery traversal methods: .parent(), .children(), .siblings(), etc.",
              "Filtering elements with .filter() and .not()",
              "Hands-on exercise: Building a hierarchical dropdown menu."
            ]
          },
          {
            "title": "Session 3.2: jQuery Plugins",
            "content": [
              "What are jQuery plugins?",
              "Finding and including plugins",
              "Using popular jQuery plugins (e.g., Slick Carousel, Lightbox)",
              "Creating custom jQuery plugins",
              "Hands-on exercise: Integrating a plugin into a project."
            ]
          },
          {
            "title": "Session 3.3: jQuery UI Basics",
            "content": [
              "Introduction to jQuery UI",
              "Using jQuery UI components: Datepicker, Dialog, etc.",
              "Customizing jQuery UI themes",
              "Hands-on exercise: Creating a draggable and sortable list."
            ]
          }
        ]
      },
      "module_4": {
        "title": "Best Practices and Project",
        "topics": 3,
        "expanded": true,
        "sections": [
          {
            "title": "Session 4.1: Best Practices in jQuery",
            "content": [
              "Writing efficient jQuery code",
              "Avoiding common pitfalls",
              "jQuery performance optimization techniques",
              "Debugging jQuery with Chrome Developer Tools",
              "Hands-on exercise: Refactoring and optimizing a jQuery project."
            ]
          },
          {
            "title": "Session 4.2: Building a jQuery Project (Part 1)",
            "content": [
              "Planning and structuring a jQuery project",
              "Setting up the project environment",
              "Implementing project features (Part 1)",
              "Hands-on exercise: Start building a comprehensive web application using jQuery."
            ]
          },
          {
            "title": "Session 4.3: Building a jQuery Project (Part 2)",
            "content": [
              "Completing the project implementation",
              "Testing and debugging",
              "Final review and presentation",
              "Hands-on exercise: Complete and present the final project."
            ]
          }
        ]
      }
    }  
    },
    'backend' : {
      'java' : {
        "module_1": {
          "title": "Introduction to Java Programming",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with Java",
              "content": [
                "What is Java and its History",
                "Java Development Kit (JDK) and Integrated Development Environment (IDE) Setup",
                "Writing and Running Your First Java Program",
                "Understanding the Java Virtual Machine (JVM)",
                "Hands-on Exercise: Creating a 'Hello, World!' application"
              ]
            },
            {
              "title": "Session 1.2: Basic Java Syntax and Data Types",
              "content": [
                "Java Syntax: Variables, Data Types, and Operators",
                "Primitive Data Types and Type Casting",
                "Working with Strings and String Manipulation",
                "Hands-on Exercise: Building a basic calculator"
              ]
            },
            {
              "title": "Session 1.3: Control Structures",
              "content": [
                "Conditional Statements: if, else, switch",
                "Loops: for, while, do-while",
                "Introduction to Arrays",
                "Hands-on Exercise: Creating a number guessing game"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Object-Oriented Programming (OOP) in Java",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 2.1: Introduction to OOP Concepts",
              "content": [
                "Classes and Objects",
                "Fields, Methods, and Constructors",
                "Access Modifiers and Encapsulation",
                "Hands-on Exercise: Designing a basic class with methods and constructors"
              ]
            },
            {
              "title": "Session 2.2: Inheritance and Polymorphism",
              "content": [
                "Inheritance and the 'extends' Keyword",
                "Overriding Methods and Polymorphism",
                "Abstract Classes and Interfaces",
                "Hands-on Exercise: Building a class hierarchy with inheritance"
              ]
            },
            {
              "title": "Session 2.3: Encapsulation and Packages",
              "content": [
                "Encapsulation: Getters and Setters",
                "Organizing Classes into Packages",
                "Introduction to Java API and Standard Libraries",
                "Hands-on Exercise: Refactoring code into packages and using access modifiers"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Advanced Java Concepts",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 3.1: Exception Handling",
              "content": [
                "Exceptions and Error Handling",
                "Using try-catch Blocks and finally",
                "Creating Custom Exceptions",
                "Hands-on Exercise: Implementing error handling in an application"
              ]
            },
            {
              "title": "Session 3.2: Collections Framework",
              "content": [
                "Overview of Java Collections Framework",
                "Working with Lists, Sets, and Maps",
                "Iterating Over Collections",
                "Hands-on Exercise: Creating a contact management application using Collections"
              ]
            },
            {
              "title": "Session 3.3: File I/O in Java",
              "content": [
                "Reading and Writing Files",
                "Working with File Streams and Buffers",
                "Serialization and Deserialization",
                "Hands-on Exercise: Building a file-based data storage system"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Multithreading and Concurrency",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 4.1: Introduction to Multithreading",
              "content": [
                "Threads and Runnable Interface",
                "Creating and Managing Threads",
                "Synchronization and Thread Safety",
                "Hands-on Exercise: Implementing a multi-threaded application"
              ]
            },
            {
              "title": "Session 4.2: Concurrency in Java",
              "content": [
                "Concurrency and Challenges",
                "Using the java.util.concurrent Package",
                "Executors, Callables, and Futures",
                "Hands-on Exercise: Implementing concurrent tasks with the Executor framework"
              ]
            },
            {
              "title": "Session 4.3: Advanced Multithreading",
              "content": [
                "Deadlock, Livelock, and Thread Starvation",
                "Thread Communication: wait, notify, notifyAll",
                "Thread Pooling",
                "Hands-on Exercise: Resolving concurrency issues in an application"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Java Networking and Database Connectivity",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 5.1: Java Networking Basics",
              "content": [
                "Sockets and Networking in Java",
                "Client-Server Architecture",
                "Building a Simple Chat Application",
                "Hands-on Exercise: Implementing a client-server application using sockets"
              ]
            },
            {
              "title": "Session 5.2: Introduction to JDBC (Java Database Connectivity)",
              "content": [
                "Connecting Java Applications to Databases",
                "Executing SQL Queries from Java",
                "CRUD Operations with JDBC",
                "Hands-on Exercise: Building a database-driven application"
              ]
            },
            {
              "title": "Session 5.3: Advanced JDBC",
              "content": [
                "Using PreparedStatement and CallableStatement",
                "Transaction Management",
                "Handling SQL Exceptions",
                "Hands-on Exercise: Implementing a transaction-based application"
              ]
            }
          ]
        },
        "module_6": {
          "title": "Java Best Practices and Project",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 6.1: Java Best Practices",
              "content": [
                "Writing Clean and Efficient Java Code",
                "Design Patterns in Java",
                "Code Optimization and Refactoring",
                "Hands-on Exercise: Refactoring and optimizing an existing application"
              ]
            },
            {
              "title": "Session 6.2: Building a Java Project (Part 1)",
              "content": [
                "Planning and Structuring a Java Project",
                "Setting Up the Project Environment and Tools",
                "Implementing Core Features (Part 1)",
                "Hands-on Exercise: Start building a comprehensive Java application"
              ]
            },
            {
              "title": "Session 6.3: Building a Java Project (Part 2)",
              "content": [
                "Completing the Project Implementation",
                "Testing, Debugging, and Final Review",
                "Deploying the Java Application",
                "Hands-on Exercise: Finalize and present the Java project"
              ]
            }
          ]
        }
      },
      'springboot' :  {
        "module_1": {
          "title": "Introduction to Spring Boot",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with Spring Boot",
              "content": [
                "Introduction to Spring Boot: Features and Benefits",
                "Setting Up a Spring Boot Development Environment",
                "Understanding Spring Boot Starter Projects",
                "Creating Your First Spring Boot Application",
                "Hands-on Exercise: Building a basic 'Hello, World!' Spring Boot application"
              ]
            },
            {
              "title": "Session 1.2: Core Concepts and Architecture",
              "content": [
                "Spring Boot Architecture: Auto-Configuration and Starter Dependencies",
                "Understanding the Spring Boot Application Lifecycle",
                "Configuration Files and Properties",
                "Hands-on Exercise: Customizing application configuration"
              ]
            },
            {
              "title": "Session 1.3: Spring Boot Initializr and Project Structure",
              "content": [
                "Using Spring Boot Initializr to Generate Projects",
                "Understanding the Project Structure and Main Components",
                "Hands-on Exercise: Creating and exploring a Spring Boot project using Spring Initializr"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Developing RESTful Services",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Building RESTful APIs",
              "content": [
                "Introduction to REST and RESTful Services",
                "Creating REST Controllers and Request Mapping",
                "Handling HTTP Requests and Responses",
                "Hands-on Exercise: Implementing a simple REST API"
              ]
            },
            {
              "title": "Session 2.2: Request and Response Handling",
              "content": [
                "Handling Request Parameters and Path Variables",
                "Customizing Response Formats (JSON, XML)",
                "Error Handling and Exception Handling",
                "Hands-on Exercise: Enhancing the REST API with error handling"
              ]
            },
            {
              "title": "Session 2.3: Data Validation and Documentation",
              "content": [
                "Validating Request Data with JSR-303/JSR-380",
                "Using Swagger for API Documentation",
                "Hands-on Exercise: Adding data validation and integrating Swagger"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Data Access with Spring Data JPA",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Introduction to Spring Data JPA",
              "content": [
                "Overview of JPA and Hibernate",
                "Configuring Data Sources and JPA Repositories",
                "Creating Entity Classes and Repository Interfaces",
                "Hands-on Exercise: Setting up Spring Data JPA and creating basic CRUD operations"
              ]
            },
            {
              "title": "Session 3.2: Advanced Data Operations",
              "content": [
                "Using Query Methods and JPQL",
                "Implementing Pagination and Sorting",
                "Hands-on Exercise: Advanced data querying and manipulation"
              ]
            },
            {
              "title": "Session 3.3: Transactions and Database Management",
              "content": [
                "Managing Transactions with Spring Boot",
                "Handling Database Migrations with Flyway or Liquibase",
                "Hands-on Exercise: Implementing transaction management and database migrations"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Security and Authentication",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Introduction to Spring Security",
              "content": [
                "Understanding Spring Security Basics",
                "Configuring Security for Spring Boot Applications",
                "Implementing Basic Authentication and Authorization",
                "Hands-on Exercise: Securing a REST API with Spring Security"
              ]
            },
            {
              "title": "Session 4.2: Advanced Security Features",
              "content": [
                "Implementing JWT Authentication",
                "Configuring OAuth2 and Social Login Integration",
                "Hands-on Exercise: Adding advanced security features to the application"
              ]
            },
            {
              "title": "Session 4.3: Security Best Practices",
              "content": [
                "Best Practices for Securing Spring Boot Applications",
                "Common Security Vulnerabilities and How to Avoid Them",
                "Hands-on Exercise: Reviewing and enhancing application security"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Configuration, Deployment, and Monitoring",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 5.1: Configuration Management",
              "content": [
                "Understanding Spring Boot Configuration Properties",
                "Externalizing Configuration with Profiles and Environment Variables",
                "Hands-on Exercise: Managing configurations for different environments"
              ]
            },
            {
              "title": "Session 5.2: Deployment Strategies",
              "content": [
                "Packaging Spring Boot Applications for Deployment",
                "Deploying to Cloud Platforms (e.g., AWS, Heroku, Azure)",
                "Hands-on Exercise: Deploying a Spring Boot application to a cloud service"
              ]
            },
            {
              "title": "Session 5.3: Monitoring and Performance Tuning",
              "content": [
                "Integrating Actuator for Application Monitoring",
                "Performance Tuning and Optimization Techniques",
                "Hands-on Exercise: Setting up monitoring and performance optimization"
              ]
            }
          ]
        },
        "module_6": {
          "title": "Project Development and Best Practices",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 6.1: Best Practices and Design Patterns",
              "content": [
                "Applying Best Practices in Spring Boot Development",
                "Common Design Patterns and Anti-Patterns",
                "Hands-on Exercise: Refactoring code using best practices"
              ]
            },
            {
              "title": "Session 6.2: Building a Comprehensive Project (Part 1)",
              "content": [
                "Project Planning and Structuring",
                "Implementing Core Features and Functionality (Part 1)",
                "Hands-on Exercise: Starting a comprehensive project with core features"
              ]
            },
            {
              "title": "Session 6.3: Building a Comprehensive Project (Part 2)",
              "content": [
                "Completing Project Implementation",
                "Testing, Debugging, and Final Review",
                "Hands-on Exercise: Finalizing and presenting the Spring Boot project"
              ]
            }
          ]
        }
      },
      'nodejs' : {
        "module_1": {
          "title": "Introduction to Node.js",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Introduction to Node.js",
              "content": [
                "What is Node.js and its History",
                "Node.js Architecture: Event-Driven, Non-Blocking I/O",
                "Setting Up Node.js Development Environment",
                "Writing Your First Node.js Script",
                "Hands-on Exercise: Building a simple 'Hello, World!' application"
              ]
            },
            {
              "title": "Session 1.2: Core Concepts and Modules",
              "content": [
                "Understanding Node.js Core Modules: fs, http, path, events",
                "Working with NPM (Node Package Manager)",
                "Creating and Using Custom Modules",
                "Hands-on Exercise: Building a basic file system utility"
              ]
            },
            {
              "title": "Session 1.3: Asynchronous Programming",
              "content": [
                "Introduction to Asynchronous Programming",
                "Callbacks, Promises, and Async/Await",
                "Error Handling in Asynchronous Code",
                "Hands-on Exercise: Implementing an asynchronous file reader"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Web Development with Express.js",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Getting Started with Express.js",
              "content": [
                "Introduction to Express.js Framework",
                "Setting Up Express.js Project",
                "Understanding Middleware and Routing",
                "Hands-on Exercise: Creating a basic web server with Express"
              ]
            },
            {
              "title": "Session 2.2: Working with Databases",
              "content": [
                "Introduction to MongoDB and Mongoose",
                "Connecting Express.js to MongoDB",
                "Performing CRUD Operations",
                "Hands-on Exercise: Building a simple CRUD application"
              ]
            },
            {
              "title": "Session 2.3: Authentication and Authorization",
              "content": [
                "Implementing User Authentication with Passport.js",
                "Understanding Session Management and JWT (JSON Web Tokens)",
                "Hands-on Exercise: Adding authentication to the CRUD application"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Real-Time Communication",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Introduction to WebSocket",
              "content": [
                "Understanding WebSocket Protocol",
                "Setting Up WebSocket Server with ws",
                "Building Real-Time Chat Application",
                "Hands-on Exercise: Creating a basic chat server with WebSocket"
              ]
            },
            {
              "title": "Session 3.2: Integrating WebSocket with Express",
              "content": [
                "Combining WebSocket with Express for Real-Time Features",
                "Handling Multiple WebSocket Connections",
                "Hands-on Exercise: Adding real-time features to the CRUD application"
              ]
            },
            {
              "title": "Session 3.3: Advanced WebSocket Features",
              "content": [
                "Broadcasting Messages and Handling Large Data",
                "Securing WebSocket Connections",
                "Hands-on Exercise: Implementing advanced WebSocket features in a project"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Testing and Debugging",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Introduction to Testing in Node.js",
              "content": [
                "Overview of Testing Frameworks: Mocha, Chai, Jest",
                "Writing Unit Tests and Integration Tests",
                "Hands-on Exercise: Writing tests for the CRUD application"
              ]
            },
            {
              "title": "Session 4.2: Debugging Node.js Applications",
              "content": [
                "Using Node.js Built-in Debugger",
                "Debugging with Chrome DevTools and VS Code",
                "Hands-on Exercise: Debugging and fixing issues in an application"
              ]
            },
            {
              "title": "Session 4.3: Performance Optimization",
              "content": [
                "Understanding Node.js Performance Metrics",
                "Techniques for Optimizing Node.js Applications",
                "Hands-on Exercise: Analyzing and optimizing application performance"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Deployment and Best Practices",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 5.1: Deployment Strategies",
              "content": [
                "Packaging and Deploying Node.js Applications",
                "Deploying to Cloud Platforms: Heroku, AWS, Azure",
                "Hands-on Exercise: Deploying a Node.js application to a cloud service"
              ]
            },
            {
              "title": "Session 5.2: Configuration Management",
              "content": [
                "Managing Environment Variables",
                "Using Configuration Libraries",
                "Hands-on Exercise: Configuring and managing application settings"
              ]
            },
            {
              "title": "Session 5.3: Best Practices and Code Quality",
              "content": [
                "Coding Standards and Best Practices",
                "Using Linters and Formatters",
                "Hands-on Exercise: Refactoring code and implementing best practices"
              ]
            }
          ]
        },
        "module_6": {
          "title": "Final Project",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 6.1: Project Planning and Development (Part 1)",
              "content": [
                "Planning and Structuring a Node.js Project",
                "Implementing Core Features (Part 1)",
                "Hands-on Exercise: Starting a comprehensive Node.js project"
              ]
            },
            {
              "title": "Session 6.2: Project Development (Part 2)",
              "content": [
                "Completing Implementation and Integration",
                "Testing and Debugging",
                "Hands-on Exercise: Finalizing the project and preparing for deployment"
              ]
            },
            {
              "title": "Session 6.3: Project Presentation and Review",
              "content": [
                "Final Review and Presentation",
                "Discussing Project Improvements and Future Enhancements",
                "Hands-on Exercise: Presenting and showcasing the final project"
              ]
            }
          ]
        }
      }                   
    },
    'database' : {
      'mysql' : {
        "module_1": {
          "title": "Introduction to MySQL",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with MySQL",
              "content": [
                "What is MySQL and its History",
                "Installing and Setting Up MySQL",
                "MySQL Workbench and Command-Line Interface",
                "Creating Your First Database and Table",
                "Hands-on Exercise: Setting up MySQL and creating a basic database"
              ]
            },
            {
              "title": "Session 1.2: Understanding Relational Databases",
              "content": [
                "Relational Database Concepts and Terminology",
                "MySQL Database Architecture",
                "Understanding Tables, Rows, and Columns",
                "Hands-on Exercise: Creating tables and understanding data types"
              ]
            },
            {
              "title": "Session 1.3: Basic SQL Queries",
              "content": [
                "Introduction to SQL Syntax",
                "Selecting Data with SELECT Statements",
                "Filtering Data with WHERE Clause",
                "Sorting and Limiting Results",
                "Hands-on Exercise: Writing basic SQL queries to retrieve data"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Advanced SQL and Database Design",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Complex SQL Queries",
              "content": [
                "Joining Tables with INNER JOIN, LEFT JOIN, RIGHT JOIN",
                "Using Subqueries and Nested Queries",
                "Grouping Data with GROUP BY and HAVING",
                "Hands-on Exercise: Writing complex queries involving multiple tables"
              ]
            },
            {
              "title": "Session 2.2: Database Design and Normalization",
              "content": [
                "Principles of Database Design",
                "Normal Forms: 1NF, 2NF, 3NF",
                "Avoiding Redundancy and Ensuring Data Integrity",
                "Hands-on Exercise: Designing a normalized database schema"
              ]
            },
            {
              "title": "Session 2.3: Indexes and Performance Optimization",
              "content": [
                "Understanding Indexes and Their Importance",
                "Creating and Managing Indexes",
                "Query Optimization Techniques",
                "Hands-on Exercise: Optimizing queries using indexes"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Stored Procedures, Functions, and Triggers",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Stored Procedures and Functions",
              "content": [
                "Introduction to Stored Procedures",
                "Creating and Executing Stored Procedures",
                "Defining and Using Functions in MySQL",
                "Hands-on Exercise: Implementing stored procedures and functions"
              ]
            },
            {
              "title": "Session 3.2: Triggers and Events",
              "content": [
                "What are Triggers and How to Use Them",
                "Creating Triggers for Data Integrity and Automation",
                "Scheduling Tasks with MySQL Events",
                "Hands-on Exercise: Creating triggers and scheduled events"
              ]
            },
            {
              "title": "Session 3.3: Views and Transactions",
              "content": [
                "Creating and Managing Views",
                "Introduction to Transactions",
                "Using COMMIT and ROLLBACK for Transaction Management",
                "Hands-on Exercise: Implementing transactions and views in a project"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Database Security and User Management",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: User Management in MySQL",
              "content": [
                "Creating and Managing MySQL Users",
                "Granting and Revoking Privileges",
                "Understanding Roles and Permissions",
                "Hands-on Exercise: Managing users and roles in MySQL"
              ]
            },
            {
              "title": "Session 4.2: Securing MySQL Databases",
              "content": [
                "Best Practices for Database Security",
                "Implementing SSL/TLS for MySQL Connections",
                "Data Encryption Techniques",
                "Hands-on Exercise: Securing a MySQL database instance"
              ]
            },
            {
              "title": "Session 4.3: Backup and Recovery Strategies",
              "content": [
                "Importance of Regular Backups",
                "Performing Backups with mysqldump",
                "Restoring Databases from Backups",
                "Hands-on Exercise: Implementing a backup and recovery plan"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Advanced MySQL Features",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 5.1: Partitioning and Sharding",
              "content": [
                "Introduction to Database Partitioning",
                "Implementing Partitioning Strategies",
                "Understanding Database Sharding",
                "Hands-on Exercise: Partitioning a large table for performance"
              ]
            },
            {
              "title": "Session 5.2: MySQL Replication",
              "content": [
                "Setting Up Master-Slave Replication",
                "Monitoring and Managing Replication",
                "Troubleshooting Replication Issues",
                "Hands-on Exercise: Implementing replication in a MySQL environment"
              ]
            },
            {
              "title": "Session 5.3: Performance Tuning and Monitoring",
              "content": [
                "Monitoring MySQL Performance with EXPLAIN and SHOW",
                "Performance Tuning Techniques",
                "Using MySQL Performance Schema",
                "Hands-on Exercise: Tuning the performance of a MySQL database"
              ]
            }
          ]
        },
        "module_6": {
          "title": "Final Project",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 6.1: Project Planning and Design",
              "content": [
                "Planning a Real-World MySQL Project",
                "Designing the Database Schema",
                "Setting Up the Project Environment",
                "Hands-on Exercise: Start working on the final project"
              ]
            },
            {
              "title": "Session 6.2: Implementation and Optimization",
              "content": [
                "Implementing Core Features and Functionality",
                "Performance Optimization and Security Implementation",
                "Hands-on Exercise: Developing and optimizing the final project"
              ]
            },
            {
              "title": "Session 6.3: Final Project Review and Presentation",
              "content": [
                "Testing and Debugging the Project",
                "Presenting the Project and Reviewing Key Learnings",
                "Hands-on Exercise: Final presentation and review of the project"
              ]
            }
          ]
        }
      },
      'mongodb'  : {
        "module_1": {
          "title": "Introduction to MongoDB",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with MongoDB",
              "content": [
                "What is MongoDB and its History",
                "Understanding NoSQL Databases",
                "Installing and Setting Up MongoDB",
                "Introduction to MongoDB Shell",
                "Hands-on Exercise: Setting up MongoDB and creating a basic database"
              ]
            },
            {
              "title": "Session 1.2: Understanding MongoDB Architecture",
              "content": [
                "Documents, Collections, and Databases",
                "MongoDB Data Model and BSON",
                "CRUD Operations in MongoDB",
                "Hands-on Exercise: Performing basic CRUD operations"
              ]
            },
            {
              "title": "Session 1.3: Basic Querying in MongoDB",
              "content": [
                "Introduction to MongoDB Query Language (MQL)",
                "Filtering Data with Queries",
                "Using Projection to Select Specific Fields",
                "Hands-on Exercise: Writing basic queries to retrieve data"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Advanced Querying and Indexing",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Complex Queries",
              "content": [
                "Querying Arrays and Embedded Documents",
                "Using Aggregation Framework",
                "Working with Pipeline Stages",
                "Hands-on Exercise: Writing complex aggregation queries"
              ]
            },
            {
              "title": "Session 2.2: Indexing for Performance",
              "content": [
                "Understanding Indexes in MongoDB",
                "Creating and Managing Indexes",
                "Indexing Strategies for Performance Optimization",
                "Hands-on Exercise: Implementing indexes to optimize queries"
              ]
            },
            {
              "title": "Session 2.3: Data Modeling Best Practices",
              "content": [
                "Schema Design Patterns in MongoDB",
                "One-to-One, One-to-Many, and Many-to-Many Relationships",
                "Modeling Hierarchical Data",
                "Hands-on Exercise: Designing a database schema with best practices"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Aggregation, Replication, and Sharding",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Advanced Aggregation Techniques",
              "content": [
                "Using $lookup for Joins",
                "Performing Grouping and Sorting",
                "Implementing Map-Reduce",
                "Hands-on Exercise: Building complex aggregation pipelines"
              ]
            },
            {
              "title": "Session 3.2: MongoDB Replication",
              "content": [
                "Understanding Replica Sets",
                "Setting Up and Managing Replica Sets",
                "Failover and Data Consistency",
                "Hands-on Exercise: Implementing replication in MongoDB"
              ]
            },
            {
              "title": "Session 3.3: Sharding in MongoDB",
              "content": [
                "Introduction to Sharding",
                "Setting Up Sharded Clusters",
                "Balancing and Managing Shards",
                "Hands-on Exercise: Sharding a large dataset for scalability"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Security and Administration",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: MongoDB Security Best Practices",
              "content": [
                "User Authentication and Roles",
                "Implementing SSL/TLS Encryption",
                "Managing Database Security",
                "Hands-on Exercise: Securing a MongoDB deployment"
              ]
            },
            {
              "title": "Session 4.2: Backup and Recovery",
              "content": [
                "Understanding Backup Strategies",
                "Performing Backups with mongodump and mongorestore",
                "Automating Backup Processes",
                "Hands-on Exercise: Setting up and testing backup and recovery"
              ]
            },
            {
              "title": "Session 4.3: Monitoring and Performance Tuning",
              "content": [
                "Monitoring MongoDB with MMS",
                "Understanding Performance Metrics",
                "Tuning MongoDB for Optimal Performance",
                "Hands-on Exercise: Analyzing and optimizing a MongoDB instance"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Advanced MongoDB Features",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 5.1: Working with Geospatial Data",
              "content": [
                "Introduction to Geospatial Indexes",
                "Querying Geospatial Data",
                "Building Location-Based Applications",
                "Hands-on Exercise: Implementing geospatial queries"
              ]
            },
            {
              "title": "Session 5.2: Full-Text Search in MongoDB",
              "content": [
                "Enabling and Using Full-Text Search",
                "Text Indexes and Queries",
                "Implementing Search Features in Applications",
                "Hands-on Exercise: Building a full-text search engine"
              ]
            },
            {
              "title": "Session 5.3: Transactions in MongoDB",
              "content": [
                "Understanding ACID Transactions",
                "Implementing Multi-Document Transactions",
                "Managing Rollbacks and Commitments",
                "Hands-on Exercise: Using transactions to ensure data integrity"
              ]
            }
          ]
        },
        "module_6": {
          "title": "Final Project",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 6.1: Project Planning and Design",
              "content": [
                "Planning a Real-World MongoDB Project",
                "Designing the Database Schema",
                "Setting Up the Project Environment",
                "Hands-on Exercise: Start working on the final project"
              ]
            },
            {
              "title": "Session 6.2: Implementation and Optimization",
              "content": [
                "Implementing Core Features and Functionality",
                "Performance Optimization and Security Implementation",
                "Hands-on Exercise: Developing and optimizing the final project"
              ]
            },
            {
              "title": "Session 6.3: Final Project Review and Presentation",
              "content": [
                "Testing and Debugging the Project",
                "Presenting the Project and Reviewing Key Learnings",
                "Hands-on Exercise: Final presentation and review of the project"
              ]
            }
          ]
        }
      }
    },
    'ui_ux' : {
      'figma' : {
        "module_1": {
          "title": "Introduction to Figma",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with Figma",
              "content": [
                "Overview of Figma and its features",
                "Setting up a Figma account and workspace",
                "Navigating the Figma interface",
                "Hands-on Exercise: Creating your first Figma design file"
              ]
            },
            {
              "title": "Session 1.2: Core Design Tools and Features",
              "content": [
                "Understanding Figma’s design tools: Shapes, Text, and Pen Tool",
                "Working with Layers and Groups",
                "Introduction to Frames and Layouts",
                "Hands-on Exercise: Designing a simple layout"
              ]
            },
            {
              "title": "Session 1.3: Working with Components and Assets",
              "content": [
                "Creating and using Components",
                "Managing Assets and Libraries",
                "Utilizing Figma's design systems",
                "Hands-on Exercise: Building a reusable component library"
              ]
            }
          ]
        },
        "module_2": {
          "title": "UI/UX Design with Figma",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Designing User Interfaces",
              "content": [
                "Principles of UI Design",
                "Creating Interactive UI Elements",
                "Designing for different screen sizes and devices",
                "Hands-on Exercise: Designing a mobile app interface"
              ]
            },
            {
              "title": "Session 2.2: Prototyping and Interaction Design",
              "content": [
                "Building interactive prototypes with Figma",
                "Setting up transitions and animations",
                "Testing and refining prototypes",
                "Hands-on Exercise: Creating a clickable prototype for a web app"
              ]
            },
            {
              "title": "Session 2.3: User Experience and Usability Testing",
              "content": [
                "Conducting usability testing with prototypes",
                "Gathering feedback and iterating on designs",
                "Analyzing user interactions and improving usability",
                "Hands-on Exercise: Conducting a usability test and refining your design"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Advanced Design Techniques",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Design Systems and Patterns",
              "content": [
                "Creating and managing design systems",
                "Applying design patterns for consistency",
                "Using Figma’s Styles and Components",
                "Hands-on Exercise: Building a design system for a web application"
              ]
            },
            {
              "title": "Session 3.2: Collaboration and Feedback",
              "content": [
                "Collaborating with team members in Figma",
                "Providing and managing feedback",
                "Utilizing Figma’s comment and sharing features",
                "Hands-on Exercise: Collaborating on a team project and incorporating feedback"
              ]
            },
            {
              "title": "Session 3.3: Integrations and Exporting Assets",
              "content": [
                "Integrating Figma with other tools and platforms",
                "Exporting assets for development",
                "Creating design specs for handoff to developers",
                "Hands-on Exercise: Exporting a design and preparing documentation for developers"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Project Development and Best Practices",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Planning and Structuring a Design Project",
              "content": [
                "Defining project goals and scope",
                "Organizing and structuring design files",
                "Setting up workflows and best practices",
                "Hands-on Exercise: Planning and structuring a comprehensive design project"
              ]
            },
            {
              "title": "Session 4.2: Implementing and Refining Designs",
              "content": [
                "Developing core features and refining designs",
                "Applying best practices and design standards",
                "Iterating based on feedback and testing",
                "Hands-on Exercise: Implementing and refining your design project"
              ]
            },
            {
              "title": "Session 4.3: Final Presentation and Review",
              "content": [
                "Presenting your design project",
                "Reviewing key learnings and project outcomes",
                "Discussing future enhancements and best practices",
                "Hands-on Exercise: Presenting and showcasing your final design project"
              ]
            }
          ]
        }
      },
      'html' : {
        "module_1": {
          "title": "Introduction to HTML",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with HTML",
              "content": [
                "Introduction to HTML and its role in web development",
                "Understanding HTML document structure: Doctype, html, head, body",
                "Setting up a development environment and tools",
                "Hands-on Exercise: Creating your first HTML document"
              ]
            },
            {
              "title": "Session 1.2: Basic HTML Elements and Tags",
              "content": [
                "Common HTML tags: headings, paragraphs, lists, and links",
                "Formatting text: bold, italic, underline, and text alignment",
                "Creating and embedding images and multimedia",
                "Hands-on Exercise: Building a simple web page with basic HTML elements"
              ]
            },
            {
              "title": "Session 1.3: HTML Attributes and Links",
              "content": [
                "Understanding HTML attributes and their usage",
                "Creating hyperlinks and understanding the href attribute",
                "Linking to external resources and other web pages",
                "Hands-on Exercise: Adding links and attributes to your web page"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Advanced HTML5 Features",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: HTML5 Semantics",
              "content": [
                "Introduction to HTML5 semantic elements: header, footer, article, section",
                "Using semantic tags to improve SEO and accessibility",
                "Hands-on Exercise: Structuring a web page with semantic HTML5 elements"
              ]
            },
            {
              "title": "Session 2.2: Forms and Input Types",
              "content": [
                "Creating forms: form, input, textarea, select",
                "Understanding different input types: text, email, number, date, etc.",
                "Validating form data with HTML5 attributes",
                "Hands-on Exercise: Building a contact form with validation"
              ]
            },
            {
              "title": "Session 2.3: Multimedia and Embedding Content",
              "content": [
                "Using HTML5 elements for audio and video: audio, video, source",
                "Embedding content with iframes and object tags",
                "Hands-on Exercise: Embedding a video and audio player in your web page"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Responsive Design and Accessibility",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Responsive Web Design with HTML",
              "content": [
                "Introduction to responsive design principles",
                "Using meta tags for viewport control",
                "Creating responsive layouts with HTML",
                "Hands-on Exercise: Designing a responsive web page layout"
              ]
            },
            {
              "title": "Session 3.2: Accessibility Best Practices",
              "content": [
                "Understanding web accessibility and its importance",
                "Implementing ARIA roles and attributes",
                "Creating accessible forms and interactive elements",
                "Hands-on Exercise: Enhancing accessibility of your web page"
              ]
            },
            {
              "title": "Session 3.3: HTML Best Practices and Optimization",
              "content": [
                "Writing clean, maintainable HTML code",
                "Organizing HTML documents and using comments",
                "Performance considerations and optimizing HTML",
                "Hands-on Exercise: Refactoring and optimizing your HTML code"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Final Project and Review",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Project Planning and Design",
              "content": [
                "Planning a comprehensive HTML project",
                "Structuring and organizing the project",
                "Hands-on Exercise: Planning and starting your final project"
              ]
            },
            {
              "title": "Session 4.2: Implementing and Testing",
              "content": [
                "Developing the project with all learned HTML concepts",
                "Testing the project across different devices and browsers",
                "Hands-on Exercise: Building and testing your final HTML project"
              ]
            },
            {
              "title": "Session 4.3: Presentation and Feedback",
              "content": [
                "Presenting your final project to peers or instructors",
                "Reviewing key learnings and receiving feedback",
                "Discussing potential improvements and future enhancements",
                "Hands-on Exercise: Presenting and discussing your final project"
              ]
            }
          ]
        }
      },
      'css' : {
        "module_1": {
          "title": "Introduction to CSS",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Getting Started with CSS",
              "content": [
                "Overview of CSS and its role in web design",
                "Understanding CSS syntax: selectors, properties, and values",
                "Setting up a CSS development environment",
                "Hands-on Exercise: Creating a basic CSS stylesheet"
              ]
            },
            {
              "title": "Session 1.2: CSS Basics and Styling",
              "content": [
                "Styling text: fonts, colors, and text alignment",
                "Working with backgrounds: colors, images, and gradients",
                "Styling borders, margins, and padding",
                "Hands-on Exercise: Building a simple styled web page"
              ]
            },
            {
              "title": "Session 1.3: Layout and Positioning",
              "content": [
                "Introduction to CSS box model and layout concepts",
                "Positioning elements: static, relative, absolute, and fixed",
                "Flexbox and Grid layout systems",
                "Hands-on Exercise: Creating a layout using Flexbox and Grid"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Advanced CSS Techniques",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Advanced Styling",
              "content": [
                "CSS transitions and animations",
                "Using CSS variables for dynamic styling",
                "Responsive typography and design techniques",
                "Hands-on Exercise: Adding animations and transitions to a web page"
              ]
            },
            {
              "title": "Session 2.2: Responsive Design",
              "content": [
                "Media queries for responsive design",
                "Mobile-first and desktop-first design approaches",
                "Creating fluid layouts and responsive images",
                "Hands-on Exercise: Designing a responsive web page"
              ]
            },
            {
              "title": "Session 2.3: CSS Preprocessors",
              "content": [
                "Introduction to CSS preprocessors: Sass and LESS",
                "Variables, nesting, and mixins in Sass/LESS",
                "Compiling and integrating preprocessors into your workflow",
                "Hands-on Exercise: Refactoring CSS with Sass/LESS"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Best Practices and Advanced Topics",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Accessibility and Performance",
              "content": [
                "Ensuring accessible designs with CSS",
                "Performance considerations and optimization techniques",
                "Using tools and techniques for CSS optimization",
                "Hands-on Exercise: Improving accessibility and performance of a web page"
              ]
            },
            {
              "title": "Session 3.2: Advanced Layouts and Design Patterns",
              "content": [
                "Advanced CSS layout techniques",
                "Using CSS frameworks and libraries (e.g., Bootstrap)",
                "Design patterns and best practices for maintainable CSS",
                "Hands-on Exercise: Implementing advanced layouts and design patterns"
              ]
            },
            {
              "title": "Session 3.3: Project and Best Practices",
              "content": [
                "Planning and structuring a comprehensive CSS project",
                "Implementing best practices and coding standards",
                "Presenting and reviewing the project",
                "Hands-on Exercise: Finalizing and presenting a CSS project"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Final Project and Review",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Project Planning and Design",
              "content": [
                "Defining project goals and requirements",
                "Structuring and organizing CSS files",
                "Hands-on Exercise: Planning and starting your final project"
              ]
            },
            {
              "title": "Session 4.2: Implementation and Testing",
              "content": [
                "Developing the project with advanced CSS techniques",
                "Testing across different browsers and devices",
                "Hands-on Exercise: Building and testing your final CSS project"
              ]
            },
            {
              "title": "Session 4.3: Presentation and Feedback",
              "content": [
                "Presenting your final project to peers or instructors",
                "Reviewing key learnings and receiving feedback",
                "Discussing potential improvements and future enhancements",
                "Hands-on Exercise: Presenting and discussing your final project"
              ]
            }
          ]
        }
      }
    },
    'others' : {
      'dsa' : {
        "module_1": {
          "title": "Introduction to Data Structures",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Basics of Data Structures",
              "content": [
                "Introduction to Data Structures",
                "Types of Data Structures: Primitive and Non-Primitive",
                "Importance and Applications of Data Structures",
                "Hands-on Exercise: Implementing basic data structures in a chosen language"
              ]
            },
            {
              "title": "Session 1.2: Arrays and Linked Lists",
              "content": [
                "Understanding Arrays: Operations and Applications",
                "Linked Lists: Singly and Doubly Linked Lists",
                "Basic Operations: Insertion, Deletion, and Traversal",
                "Hands-on Exercise: Implementing arrays and linked lists"
              ]
            },
            {
              "title": "Session 1.3: Stacks and Queues",
              "content": [
                "Introduction to Stacks: Operations and Applications",
                "Understanding Queues: Types and Implementations",
                "Applications of Stacks and Queues in Algorithms",
                "Hands-on Exercise: Implementing stacks and queues"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Advanced Data Structures",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Trees and Binary Trees",
              "content": [
                "Introduction to Trees and Binary Trees",
                "Tree Traversal Algorithms: Inorder, Preorder, Postorder",
                "Binary Search Trees (BST): Operations and Applications",
                "Hands-on Exercise: Implementing tree structures and traversal algorithms"
              ]
            },
            {
              "title": "Session 2.2: Heaps and Priority Queues",
              "content": [
                "Understanding Heaps: Min-Heap and Max-Heap",
                "Heap Operations: Insertion, Deletion, and Heapify",
                "Priority Queues and Their Applications",
                "Hands-on Exercise: Implementing heaps and priority queues"
              ]
            },
            {
              "title": "Session 2.3: Hash Tables",
              "content": [
                "Introduction to Hash Tables: Concepts and Implementations",
                "Handling Collisions: Chaining and Open Addressing",
                "Applications and Performance Considerations",
                "Hands-on Exercise: Implementing hash tables"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Algorithms and Problem Solving",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Sorting Algorithms",
              "content": [
                "Overview of Sorting Algorithms: Bubble, Selection, Insertion, Merge, Quick",
                "Analysis of Sorting Algorithms: Time and Space Complexity",
                "Choosing the Right Sorting Algorithm for Different Scenarios",
                "Hands-on Exercise: Implementing and comparing sorting algorithms"
              ]
            },
            {
              "title": "Session 3.2: Searching Algorithms",
              "content": [
                "Introduction to Searching Algorithms: Linear Search, Binary Search",
                "Binary Search Trees and Balanced Trees",
                "Search Techniques and Performance Analysis",
                "Hands-on Exercise: Implementing searching algorithms"
              ]
            },
            {
              "title": "Session 3.3: Graph Algorithms",
              "content": [
                "Introduction to Graphs: Representation and Types",
                "Graph Traversal Algorithms: BFS and DFS",
                "Shortest Path Algorithms: Dijkstra’s and Bellman-Ford",
                "Hands-on Exercise: Implementing graph algorithms"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Advanced Topics and Optimization",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Dynamic Programming",
              "content": [
                "Introduction to Dynamic Programming",
                "Understanding Memoization and Tabulation",
                "Solving Classic Problems: Knapsack, Longest Common Subsequence",
                "Hands-on Exercise: Implementing dynamic programming solutions"
              ]
            },
            {
              "title": "Session 4.2: Greedy Algorithms",
              "content": [
                "Introduction to Greedy Algorithms",
                "Greedy Strategies and Problems: Coin Change, Activity Selection",
                "Analyzing Greedy Algorithms",
                "Hands-on Exercise: Implementing greedy algorithms"
              ]
            },
            {
              "title": "Session 4.3: Algorithm Optimization and Complexity Analysis",
              "content": [
                "Optimizing Algorithms for Performance",
                "Understanding Big O, Big Ω, and Big Θ Notation",
                "Complexity Analysis of Algorithms",
                "Hands-on Exercise: Analyzing and optimizing algorithm performance"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Final Project and Review",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 5.1: Project Planning and Design",
              "content": [
                "Planning a Comprehensive Data Structures and Algorithms Project",
                "Designing and Structuring the Project",
                "Hands-on Exercise: Planning and starting your final project"
              ]
            },
            {
              "title": "Session 5.2: Implementation and Testing",
              "content": [
                "Developing the Project Using Advanced Data Structures and Algorithms",
                "Testing and Validating the Project",
                "Hands-on Exercise: Building and testing your final project"
              ]
            },
            {
              "title": "Session 5.3: Presentation and Feedback",
              "content": [
                "Presenting Your Final Project to Peers or Instructors",
                "Reviewing Key Learnings and Receiving Feedback",
                "Discussing Potential Improvements and Future Enhancements",
                "Hands-on Exercise: Presenting and discussing your final project"
              ]
            }
          ]
        }
      },
      'asd' : {
        "module_1": {
          "title": "Introduction to System Design",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Fundamentals of System Design",
              "content": [
                "Introduction to System Design",
                "Understanding System Requirements and Constraints",
                "Key Concepts: Scalability, Availability, and Reliability",
                "Hands-on Exercise: Defining system requirements and constraints"
              ]
            },
            {
              "title": "Session 1.2: Design Principles and Patterns",
              "content": [
                "Core Design Principles: Separation of Concerns, Modularity",
                "Common Architectural Patterns: MVC, Microservices, Monolithic",
                "Choosing the Right Design Pattern for Different Scenarios",
                "Hands-on Exercise: Applying design principles to real-world scenarios"
              ]
            },
            {
              "title": "Session 1.3: System Components and Communication",
              "content": [
                "Understanding System Components and Interfaces",
                "Communication Protocols: REST, RPC, Message Queues",
                "Designing for Interoperability and Integration",
                "Hands-on Exercise: Designing a system with multiple components and communication protocols"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Scalability and Performance",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Scaling Strategies",
              "content": [
                "Introduction to Scalability: Horizontal vs. Vertical Scaling",
                "Load Balancing Techniques and Tools",
                "Scaling Databases and Storage Solutions",
                "Hands-on Exercise: Designing a scalable architecture for a web application"
              ]
            },
            {
              "title": "Session 2.2: Performance Optimization",
              "content": [
                "Performance Metrics and Monitoring",
                "Caching Strategies: In-Memory, Distributed Caches",
                "Optimizing Database Queries and API Responses",
                "Hands-on Exercise: Implementing performance optimizations in a sample system"
              ]
            },
            {
              "title": "Session 2.3: Fault Tolerance and Reliability",
              "content": [
                "Designing for Fault Tolerance and Redundancy",
                "Implementing High Availability Solutions",
                "Disaster Recovery Planning",
                "Hands-on Exercise: Designing a fault-tolerant system"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Distributed Systems and Data Management",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Distributed System Concepts",
              "content": [
                "Introduction to Distributed Systems",
                "Challenges: Consistency, Partition Tolerance, and Availability",
                "Consensus Algorithms: Raft, Paxos",
                "Hands-on Exercise: Designing a distributed system with consistency and fault tolerance"
              ]
            },
            {
              "title": "Session 3.2: Data Storage and Management",
              "content": [
                "Data Storage Solutions: SQL vs. NoSQL Databases",
                "Designing for Data Replication and Sharding",
                "Handling Data Consistency and Integrity",
                "Hands-on Exercise: Implementing data storage and management solutions"
              ]
            },
            {
              "title": "Session 3.3: API Design and Integration",
              "content": [
                "Designing RESTful APIs and GraphQL",
                "API Security and Authentication",
                "Versioning and Documentation Best Practices",
                "Hands-on Exercise: Designing and implementing APIs for system integration"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Advanced System Design Topics",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Event-Driven Architecture",
              "content": [
                "Introduction to Event-Driven Architecture",
                "Event Producers, Consumers, and Brokers",
                "Designing for Asynchronous Processing and Messaging",
                "Hands-on Exercise: Implementing an event-driven system"
              ]
            },
            {
              "title": "Session 4.2: Microservices and Service-Oriented Architecture",
              "content": [
                "Understanding Microservices Architecture",
                "Designing and Managing Microservices",
                "Service Communication and Coordination",
                "Hands-on Exercise: Designing and deploying microservices"
              ]
            },
            {
              "title": "Session 4.3: Security and Compliance",
              "content": [
                "Security Best Practices in System Design",
                "Data Protection and Encryption Techniques",
                "Compliance and Regulatory Considerations",
                "Hands-on Exercise: Implementing security measures in a system"
              ]
            }
          ]
        },
        "module_5": {
          "title": "Final Project and Review",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 5.1: Project Planning and Design",
              "content": [
                "Defining Project Goals and Requirements",
                "Designing System Architecture and Components",
                "Hands-on Exercise: Planning and starting your final system design project"
              ]
            },
            {
              "title": "Session 5.2: Implementation and Testing",
              "content": [
                "Developing the Project Using System Design Principles",
                "Testing and Validating System Components",
                "Hands-on Exercise: Building and testing your final system design project"
              ]
            },
            {
              "title": "Session 5.3: Presentation and Feedback",
              "content": [
                "Presenting Your Final Project to Peers or Instructors",
                "Reviewing Key Learnings and Receiving Feedback",
                "Discussing Potential Improvements and Future Enhancements",
                "Hands-on Exercise: Presenting and discussing your final project"
              ]
            }
          ]
        }
      },
      'aip' : {
        "module_1": {
          "title": "Introduction to Job Interviews",
          "topics": 3,
          "expanded": true,
          "sections": [
            {
              "title": "Session 1.1: Understanding the Interview Process",
              "content": [
                "Overview of the Job Interview Process",
                "Types of Interviews: Behavioral, Technical, Panel, and Case Interviews",
                "Interview Preparation: Researching Companies and Roles",
                "Hands-on Exercise: Researching a company and role for a mock interview"
              ]
            },
            {
              "title": "Session 1.2: Preparing for Technical Interviews",
              "content": [
                "Common Technical Interview Formats and Topics",
                "Practicing Coding Problems and Technical Questions",
                "Resources and Tools for Technical Interview Preparation",
                "Hands-on Exercise: Solving coding problems and discussing solutions"
              ]
            },
            {
              "title": "Session 1.3: Preparing for Behavioral Interviews",
              "content": [
                "Understanding Behavioral Interview Questions",
                "Using the STAR Method to Structure Responses",
                "Common Behavioral Questions and How to Answer Them",
                "Hands-on Exercise: Crafting and practicing responses to behavioral questions"
              ]
            }
          ]
        },
        "module_2": {
          "title": "Communication and Presentation Skills",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 2.1: Effective Communication Techniques",
              "content": [
                "Importance of Clear and Concise Communication",
                "Techniques for Active Listening and Engaging Conversation",
                "Handling Nervousness and Building Confidence",
                "Hands-on Exercise: Practicing effective communication in mock interviews"
              ]
            },
            {
              "title": "Session 2.2: Crafting a Strong Personal Pitch",
              "content": [
                "Creating a Compelling Elevator Pitch",
                "Highlighting Key Skills and Achievements",
                "Tailoring Your Pitch to Different Interview Scenarios",
                "Hands-on Exercise: Developing and practicing your personal pitch"
              ]
            },
            {
              "title": "Session 2.3: Professional Presentation and Etiquette",
              "content": [
                "Best Practices for Professional Appearance and Body Language",
                "Interview Etiquette: Greetings, Thank-Yous, and Follow-Ups",
                "Handling Difficult Questions and Situations Gracefully",
                "Hands-on Exercise: Role-playing professional scenarios and receiving feedback"
              ]
            }
          ]
        },
        "module_3": {
          "title": "Mock Interviews and Feedback",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 3.1: Conducting Mock Interviews",
              "content": [
                "Simulating Real-World Interview Scenarios",
                "Role-Playing Different Interview Formats",
                "Receiving and Giving Constructive Feedback",
                "Hands-on Exercise: Participating in mock interviews with peer reviews"
              ]
            },
            {
              "title": "Session 3.2: Analyzing Interview Performance",
              "content": [
                "Reviewing Interview Performance and Identifying Strengths and Areas for Improvement",
                "Techniques for Self-Assessment and Reflection",
                "Adjusting Preparation Strategies Based on Feedback",
                "Hands-on Exercise: Reviewing mock interview recordings and discussing improvements"
              ]
            },
            {
              "title": "Session 3.3: Final Preparation and Confidence Building",
              "content": [
                "Final Review of Key Interview Strategies and Techniques",
                "Building Confidence and Managing Stress Before Interviews",
                "Creating a Personal Interview Preparation Checklist",
                "Hands-on Exercise: Final mock interview and confidence-building exercises"
              ]
            }
          ]
        },
        "module_4": {
          "title": "Post-Interview Strategies and Continuous Improvement",
          "topics": 3,
          "expanded": false,
          "sections": [
            {
              "title": "Session 4.1: Post-Interview Follow-Up",
              "content": [
                "Crafting Effective Thank-You Notes and Follow-Up Emails",
                "Understanding the Follow-Up Process and Timeline",
                "Handling Offers and Rejections Professionally",
                "Hands-on Exercise: Writing a thank-you note and follow-up email"
              ]
            },
            {
              "title": "Session 4.2: Learning from the Interview Experience",
              "content": [
                "Evaluating Interview Feedback and Performance",
                "Identifying Key Takeaways and Areas for Improvement",
                "Implementing Changes for Future Interviews",
                "Hands-on Exercise: Analyzing feedback and planning for future interviews"
              ]
            },
            {
              "title": "Session 4.3: Continuous Improvement and Networking",
              "content": [
                "Building a Network and Seeking Mentorship",
                "Staying Updated with Industry Trends and Interview Practices",
                "Ongoing Professional Development and Skill Enhancement",
                "Hands-on Exercise: Creating a professional development plan and networking strategy"
              ]
            }
          ]
        }
      }
    }
  });

  const moduleExpandHandler = (moduleId) =>{
    let moduleObj = Object.assign({},modules);
    moduleObj[type][course][moduleId].expanded = !moduleObj[type][course][moduleId].expanded;

    setModules(moduleObj);
  }

  return (
    <CurriculumWrapper>
      <CurriculumContent>
        <CurriculumColumn>
          <h1 className="curriculum-title">Course Curriculum</h1>
          {
            Object.keys(modules[type][course]).map((key_item) => (
              <ModuleCard key={key_item} moduleId={key_item} module={modules[type][course][key_item]} moduleExpandHandler={moduleExpandHandler} />
            ))
          }
        </CurriculumColumn>
        <DeliveryFormatColumn>
          <DeliveryFormat />
        </DeliveryFormatColumn>
      </CurriculumContent>
    </CurriculumWrapper>
  );
}

const CurriculumWrapper = styled.section`
  align-self: end;
  margin-top: 155px;
  width: 100%;
  align-items:center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 10px 0 0;
  }
`;

const CurriculumContent = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const CurriculumColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 66%;
  margin-left: 0;
  padding-left: 121px;
  @media (max-width: 991px) {
    width: 100%;
  }

  .curriculum-title {
    color: var(--Gray-900, #101828);
    text-align: center;
    align-self: center;
    font: 600 30px/1 Inter, sans-serif;
    margin-bottom: 51px;
    position: relative;
    right: 296px;
  }
`;

const DeliveryFormatColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export default CourseCurriculum;
