/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

function ModuleCard({ moduleId ,module,moduleExpandHandler }) {
  return (
    <ModuleWrapper expanded={module.expanded}>
      <ModuleTitle>{module.title}</ModuleTitle>
      {module.expanded && <ModuleContent>{
          module.sections.map((sectionObj)=>(
            <div>
              <h3>{sectionObj.title}</h3>
            <ul>
              {
                sectionObj.content.map((item)=>(
                  <li>{item}</li>
                ))
              }
            </ul>
            </div>
          ))
          
      }</ModuleContent>}
      <ModuleInfo>
        <TopicCount>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/27c5c74ba80ee2a85250c3c035e752253b0b8a27eaf43585b1048f17c6d3dae8?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            alt=""
          />
          <span>{module.topics} topics</span>
        </TopicCount>
        <ExpandIcon
          src={
            module.expanded
              ? "https://cdn.builder.io/api/v1/image/assets/TEMP/a42e27fa1c2155ef75241f13cb7b653aba4b1047de78c7e0969554302ccd13dd?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              : "https://cdn.builder.io/api/v1/image/assets/TEMP/62a044a483c5c559b63b151c3f170841e6d2b622433ee9345e5ec4e8f409cfd2?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          }
          alt=""
          onClick={()=>moduleExpandHandler(moduleId)}
        />
      </ModuleInfo>
    </ModuleWrapper>
  );
}

const ModuleWrapper = styled.article`
  border-radius: 7px;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 26px 35px;
  border: 1px solid rgba(52, 64, 84, 0.12);
  ${(props) =>
    props.expanded &&
    `
    flex-direction: column;
    align-items: flex-start;
  `}
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const ModuleTitle = styled.h2`
  color: #101828;
  font: 500 20px/38px Inter, sans-serif;
  margin: 0;
`;

const ModuleContent = styled.p`
  color: #101828;
  font-size: 16px;
  font-weight: 400;
  margin-top: 22px;
  h3{
    font-size: 15px;
    font-weight: bold;
  }
  ul{
    list-style: circle;

    li{
      padding-bottom:10px;
    }
  }
`;

const ModuleInfo = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const TopicCount = styled.div`
  display: flex;
  gap: 8px;
  color: #101828;
  font: 500 18px/38px Inter, sans-serif;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
`;

const ExpandIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor:pointer;
`;

export default ModuleCard;
