/**
 * This code was generated by Builder.io.
 */
import React, {useState} from "react";
import styled from "styled-components";
import {init,saveCouncelling} from '../../utility';

const CareerCounselling = () => {
  init();
  const [councelling,setCouncelling] = useState({email : '',mobile_number:''});
  return(
  <CounsellingContainer>
    <CounsellingContent>
      <FormColumn>
        <FormWrapper>
          <FormHeader>
            <FormTitle>Free Career Counselling</FormTitle>
            <Label htmlFor="emailInput" className="visually-hidden">
              Email
            </Label>
          </FormHeader>
          <FormFields>
            <InputField>
              <Input
                type="tel"
                id="phoneInput"
                placeholder="+91 Phone number"
                aria-label="Phone number"
                defaultValue={councelling.mobile_number} 
                onKeyUp={(event)=>{
                  let councellingObj = Object.assign({},councelling);
                  councellingObj.mobile_number = event.target.value.trim();
                  setCouncelling(councellingObj);
                }}
              />
            </InputField>
            <InputField>
              <Input
                type="email"
                id="emailInput"
                placeholder="Enter your email"
                aria-label="Email"
                defaultValue={councelling.email} 
                onKeyUp={(event)=>{
                  let councellingObj = Object.assign({},councelling);
                  councellingObj.email = event.target.value.trim();
                  setCouncelling(councellingObj);
                }}
              />
            </InputField>
          </FormFields>
        </FormWrapper>
      </FormColumn>
      <ImageColumn>
        <ImageWrapper>
          <CounsellingImage
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4dbea3abde4a68398ed4ec16b8763d4566f26fc4c9912c356d0639c3a566ec3e?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            alt="Career Counselling"
          />
          <SubmitButton onClick={(event)=>{
          event.preventDefault();
          event.stopPropagation();
          saveCouncelling(councelling).then(()=>{
            alert("Thanks, we will get back to you!");
            setCouncelling({email : '', mobile_number : ''});
          }).catch(()=>{
            alert("Sorry, could not able to process your request!");
          })
        }}>Submit</SubmitButton>
        </ImageWrapper>
      </ImageColumn>
    </CounsellingContent>
  </CounsellingContainer>
  );
};

const CounsellingContainer = styled.section`
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  margin-top: 194px;
  max-width: 100%;
  padding: 0 42px 65px;
  width: 996px;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const CounsellingContent = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 72%;
  margin-left: 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  font-family: Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FormHeader = styled.div`
  display: flex;
  gap: 35px;
`;

const FormTitle = styled.h2`
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  color:black;
`;

const Label = styled.label`
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  align-self: start;
  margin-top: 21px;
`;

const FormFields = styled.div`
  display: flex;
  margin-top: 7px;
  gap: 26px;
  font-size: 16px;
  color: var(--Gray-500, #667085);
  font-weight: 400;
  flex-wrap: wrap;
`;

const InputField = styled.div`
  display: flex;
  min-height: 44px;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  padding: 10px 14px;
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 28%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  margin-top: -66px;
  width: 100%;
  flex-direction: column;
  color: var(--White, #fff);
  white-space: nowrap;
  font: 600 16px Inter, sans-serif;

  @media (max-width: 991px) {
    margin-top: -44px;
    white-space: initial;
  }
`;

const CounsellingImage = styled.img`
  aspect-ratio: 1.01;
  object-fit: contain;
  width: 161px;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.09);
  align-self: end;
  max-width: 100%;
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  border: 1px solid var(--Sodark, #ef4236);
  background: var(
    --Sodark,
    linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%)
  );
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 17px;
  padding: 10px 18px;
  align-self: start;
  margin-left: 119px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default CareerCounselling;
