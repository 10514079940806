/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import ProgramCard from "./ProgramCard";
import FeaturesSection from "./FeaturesSection";
import CareerCounsellingForm from "./CareerCounsellingForm/CareerCounsellingForm";
import Footer from "../CommonComponents/Footer/Footer";

const Main = () => {
  const programs = [
    {
      title: "Undergraduate",
      price: "₹50,000",
      modules:
        "A platform built you full stack development champion and get your first job through strategic training",
      duration: "6 Months",
      originalPrice: "₹1,00,000",
      discountPrice: "₹50,000",
      features: [
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/314fecf736c94617578277a57e6eae33b37c93f17e1aadb28b8a94675fc15765?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Training Basic to Advance",
          label: "Training Basic to Advance",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ada75eb116ce04d256932173e2ed40d9bf3fb85c878382ffbeb7be909bac34e8?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Each Course Project Dev Mentorship",
          label: "Each Course Project Dev Mentorship",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1e127e0daa3c54d6bdd97b9150971d5f4ca73660c14cb9d300e60eb7718b96df?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "End to End web application development",
          label: "End to End web application development",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Regular Review and Feedback",
          label: "Regular Review and Feedback",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Product development work flow",
          label: "Product development work flow",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Row & High level system design",
          label: "Row & High level system design",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Data structure and algorithms",
          label: "Product development work flow",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Business communication & professional etiquette",
          label: "Business communication & professional etiquette",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Interview Preparation/Mock tests/screening tests",
          label: "Interview Preparation/Mock tests/screening tests",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Resume building & Job Assistant",
          label: "Resume building & Job Assistant",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Internship Certification",
          label: "Internship Certification",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Full stack development certification",
          label: "Full stack development certification",
        }
      ],
      buttonLabel: "Get started",
      scheduleDate: "Every Sunday",
      scheduleTime: "9:00 AM - 3:00 PM"
    },
    {
      title: "Postgraduate",
      price: "₹50,000",
      modules:
        "A platform built you full stack development champion and get your first job through strategic training",
      duration: "3 Months",
      originalPrice: "₹1,00,000",
      discountPrice: "₹50,000",
      features: [
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/314fecf736c94617578277a57e6eae33b37c93f17e1aadb28b8a94675fc15765?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Training Basic to Advance",
          label: "Training Basic to Advance",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ada75eb116ce04d256932173e2ed40d9bf3fb85c878382ffbeb7be909bac34e8?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Each Course Project Dev Mentorship",
          label: "Each Course Project Dev Mentorship",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1e127e0daa3c54d6bdd97b9150971d5f4ca73660c14cb9d300e60eb7718b96df?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "End to End web application development",
          label: "End to End web application development",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Regular Review and Feedback",
          label: "Regular Review and Feedback",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Product development work flow",
          label: "Product development work flow",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Row & High level system design",
          label: "Row & High level system design",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Data structure and algorithms",
          label: "Product development work flow",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Business communication & professional etiquette",
          label: "Business communication & professional etiquette",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Interview Preparation/Mock tests/screening tests",
          label: "Interview Preparation/Mock tests/screening tests",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Resume building & Job Assistant",
          label: "Resume building & Job Assistant",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Full stack development certification",
          label: "Full stack development certification",
        }
      ],
      buttonLabel: "Get started",
      scheduleDate: "Every WeekDays",
      scheduleTime: "9:00 AM - 2:00 PM"
    },
    {
      title: "Working Professional",
      price: "₹50,000",
      modules:
        "A platform built you full stack development champion and advance your skills through strategic training",
      duration: "3 Months",
      originalPrice: "₹1,00,000",
      discountPrice: "₹50,000",
      features: [
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/314fecf736c94617578277a57e6eae33b37c93f17e1aadb28b8a94675fc15765?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Training Basic to Advance",
          label: "Training Basic to Advance",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ada75eb116ce04d256932173e2ed40d9bf3fb85c878382ffbeb7be909bac34e8?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Each Course Project Dev Mentorship",
          label: "Each Course Project Dev Mentorship",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1e127e0daa3c54d6bdd97b9150971d5f4ca73660c14cb9d300e60eb7718b96df?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "End to End web application development",
          label: "End to End web application development",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Regular Review and Feedback",
          label: "Regular Review and Feedback",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Product development work flow",
          label: "Product development work flow",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Row & High level system design",
          label: "Row & High level system design",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Data structure and algorithms",
          label: "Product development work flow",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Business communication & professional etiquette",
          label: "Business communication & professional etiquette",
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb5a38c9cc96a1966e358f5938f78eb3b50e6b7e91ca14aba88e3805fe681c4c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
          alt: "Full stack development certification",
          label: "Full stack development certification",
        }
      ],
      buttonLabel: "Get started",
      scheduleDate: "Every Saturday",
      scheduleTime: "9:00 AM - 3:00 PM"
    }
  ];

  return (
    <MainContainer>
      <MainSection>
        <Header />
        <ProgramSection>
          <ProgramHeading>Choose a Program for your upskill</ProgramHeading>
          <ProgramsList>
            {programs.map((program, index) => (
              <ProgramCard key={index} {...program} />
            ))}
          </ProgramsList>
        </ProgramSection>
        <FeaturesSection />
        <CareerCounsellingForm />
      </MainSection>
      <Footer style={{paddingBottom:'35px'}}/>
    </MainContainer>
  );
};

const MainContainer = styled.main`
  background-color: rgba(249, 250, 251, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MainSection = styled.section`
  background-color: rgba(249, 250, 251, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
`;

const ProgramSection = styled.section`
  max-width: 1250px;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ProgramHeading = styled.h2`
  text-align: center;
  margin-top: 97px;
  color: rgba(52, 64, 84, 1);
  font: 500 38px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ProgramsList = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(391px, 0fr));
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

export default Main;
