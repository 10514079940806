/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import InputField from "./InputField";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Login submitted", { email, password });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="form-fields">
        <InputField
          label="Email"
          type="email"
          value={email}
          id="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputField
          label="Password"
          type="password"
          value={password}
          id="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="login-button">
        <div className="button-wrapper">
          <button type="submit" className="button-base">
            Login
          </button>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
