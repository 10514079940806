/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const TechStack = ({ subheading, heading, description }) => {
  return (
    <Container>
      <Content>
        <Subheading>{subheading}</Subheading>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 32px;
  margin-top:50px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 768px;
  flex-direction: column;
  align-items: center;
`;

const Subheading = styled.h3`
  color: var(--Brand-700, #6941c6);
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const Heading = styled.h2`
  color: var(--Gray-900, #101828);
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.72px;
  margin: 12px 0 0;
`;

const Description = styled.p`
  color: var(--Gray-600, #475467);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin: 20px 0 0;
`;

export default TechStack;
