import React from 'react';
import {Redirect, Route } from "react-router-dom";
import AboutUs from './AboutUs/AboutUs';
import HomePage from './new_home/HomePage';
import TechnologySection from './TechnologyPage/TechnologySection';
import CourseDetailPage from './CourseDetailPage/CourseDetailPage';
import CoursesListing from './CourseListPage/CoursesListing';
import EventPage from './EventPage/EventsPage';
import Main from './ProgramPage/Main';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import ContactUs from './ContactUs/ContactUs';
import SignUp from './SignUp/SignUp';
import LogingPage from './LoginPage/LoginPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import Brouchure from './Brouchure';
// import App from './App';
import MainComponent from './MyComponent/MainComponent';
import Policy from './policy';
import Term from './Term';

const Dashboard = (props) =>{

    return (
        <React.Fragment>
                <Route exact path={`/`}>
                    <Redirect to={{ pathname: "/home" }} />
                </Route>
                <Route exact path={`/home`}>
                    <HomePage />
                </Route>
                <Route exact path={`/about`}>
                    <AboutUs />
                </Route>
                <Route exact path={`/technologies`}>
                    <TechnologySection />
                </Route>
                <Route exact path={`/course`}>
                    <CourseDetailPage />
                </Route>
                <Route exact path={`/courses`}>
                    <CoursesListing />
                </Route>
                <Route exact path={`/events`}>
                    <EventPage />
                </Route>
                <Route exact path={`/programmes`}>
                    <Main />
                </Route>
                <Route exact path={`/contactus`}>
                    <ContactUs />
                </Route>
                <Route exact path={`/signup`}>
                    <SignUp />
                </Route>
                <Route exact path={`/login`}>
                    <LogingPage />
                </Route>
                <Route exact path={`/404`}>
                    <NotFoundPage />
                </Route>
                <Route exact path={`/app`}>
                    <MainComponent />
                </Route>
                <Route exact path={`/brouchure`}>
                    <Brouchure />
                </Route>
                <Route exact path={`/privacy`}>
                    <Policy />
                </Route>
                <Route exact path={`/terms`}>
                    <Term />
                </Route>
        </React.Fragment>
    );
};

export default Dashboard;
