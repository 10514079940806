/**
 * This code was generated by Builder.io.
 */
import React from "react";

const InputField = ({ label, type, value, id, onChange }) => {
  return (
    <div className="input-field">
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <div className="input-wrapper">
        <input
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          className="input-content"
        />
      </div>
    </div>
  );
};

export default InputField;
