/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const EnrollmentForm = () => {
  return (
    <StyledEnrollmentForm>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/99583c0395bbced65c67886ab878ebb9b7ef77b2bbcf578fbe175756ba11821d?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Enrollment decoration"
        className="decoration"
      />
      <h2>Like the course? Get started</h2>
      <form>
        <div className="form-group">
          <label htmlFor="phone" className="visually-hidden">
            Phone number
          </label>
          <input type="tel" id="phone" placeholder="+91 Phone number" />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="visually-hidden">
            Email
          </label>
          <input type="email" id="email" placeholder="Enter your email" />
        </div>
        <button type="submit">Enroll Now</button>
      </form>
    </StyledEnrollmentForm>
  );
};

const StyledEnrollmentForm = styled.section`
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  padding: 65px 56px;
  margin: 100px auto;
  max-width: 996px;
  position: relative;

  .decoration {
    position: absolute;
    top: -56px;
    right: 32px;
    width: 166px;
    z-index: 10;
  }

  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    gap: 22px;
    flex-wrap: wrap;

    .form-group {
      flex: 1;
      min-width: 200px;

      input {
        width: 100%;
        padding: 10px 14px;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        font-size: 16px;
      }
    }

    button {
      padding: 10px 18px;
      background: linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%);
      color: #fff;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    padding: 40px 20px;
    margin: 40px 10px;

    .decoration {
      right: 10px;
    }

    form {
      flex-direction: column;
    }
  }
`;

export default EnrollmentForm;
