/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import TechnologyContent from "./TechnologyContent";
import TechnologyImage from "./TechnologyImage";

const TechnologiesSection = () => {
  return (
    <StyledSection>
      <TechnologyContent />
      <TechnologyImage />
    </StyledSection>
  );
};

const StyledSection = styled.section`
  background: linear-gradient(77deg, #ef4236 3.73%, #faa93f 84.46%);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  margin-top: 24px;
  width: 100%;
  gap: 20px;
  color: #fff;
  font-weight: 500;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 80px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

export default TechnologiesSection;
