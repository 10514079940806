/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import FeaturesList from "./FeaturesList";

const FeaturesSection = () => (
  <Section>
    <Container>
      <Content>
        <TextBlock>
          <TitleBlock>
            <Title>Why Sodhark?</Title>
            <Description>Be an industry expert</Description>
          </TitleBlock>
          <SupportingText>
            Our shared values keep us connected and guide us as one team.
          </SupportingText>
        </TextBlock>
      </Content>
    </Container>
    <Container>
      <FeaturesList />
    </Container>
  </Section>
);

export default FeaturesSection;

const Section = styled.section`
  align-self: stretch;
  display: flex;
  margin-top: 76px;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  font-family: Inter, sans-serif;
  text-align: center;
  justify-content: start;
  padding: 96px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Container = styled.div`
  display: flex;
  width: 1280px;
  max-width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 0 32px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const TextBlock = styled.div`
  display: flex;
  width: 768px;
  max-width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const TitleBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  font-size: 36px;
  color: var(--Gray-900, #101828);
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: 1;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  margin: 0 auto;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 640px) {
    margin: 0 auto;
  }
`;

const Description = styled.p`
  position: relative;
  height: auto;
  margin: 20px auto 0;
  @media (max-width: 640px) {
    margin: 0 auto;
  }
`;

const SupportingText = styled.p`
  color: var(--Gray-600, #475467);
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
