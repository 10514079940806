/**
 * This code was generated by Builder.io.
 */
import styled from "styled-components";

export const FormContainer = styled.section`
  border-radius: 7px;
  align-self: center;
  margin-top: 53px;
  width: 996px;
  max-width: 100%;
  padding: 0 42px 65px;
  border: 1px solid rgba(0, 0, 0, 0.16);

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

export const FlexContainer = styled.div`
  gap: 20px;
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 72%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 28%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const ColumnContent = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  font-family: Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: 35px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  margin-top: 7px;
  gap: 26px;
  font-size: 16px;
  color: var(--Gray-500, #667085);
  font-weight: 400;
  flex-wrap: wrap;
`;
