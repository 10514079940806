/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const LogoGroup = ({ logos, subGroup, className }) => {
  return (
    <Container className={className}>
      {logos.map((logo, index) => (
        <Logo
          key={index}
          src={logo.src}
          alt={logo.alt}
          className={subGroup && subGroup.includes(index) ? "sub-group" : ""}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  margin: 30px 0 0 56px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }

  &.bottom-logos {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
  object-fit: contain;
  position:relative;
  left:180px;

  &.sub-group {
    width: 48px;
    height: 48px;
  }
`;

export default LogoGroup;
