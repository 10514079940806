/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  text-align: center;
  padding: 20px 0;
  color: #072c50;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <p>&copy; 2023 Your Company Name. All rights reserved.</p>
    </FooterWrapper>
  );
};

export default Footer;
