/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const SubNavigation = () => (
  <SubNavigationContainer>
    <NavItem active>Beginner</NavItem>
    <NavItem>Intermediate</NavItem>
    <NavItem>Advanced</NavItem>
    <NavItem>Other Levels</NavItem>
    <NavItem>Training</NavItem>
    <NavItem>SQL</NavItem>
  </SubNavigationContainer>
);

const SubNavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #344054;
  flex-wrap: wrap;
  margin: 29px 0 0 99px;
  font: 600 16px Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const NavItem = styled.div`
  border-radius: 68px;
  background-color: ${({ active }) =>
    active ? "rgba(1, 0, 76, 1)" : "var(--White, #fff)"};
  color: ${({ active }) => (active ? "var(--Gray-6, #f2f2f2)" : "inherit")};
  padding: 8px 12px;
  white-space: nowrap;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default SubNavigation;
