import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';

const App = ({ history }) => (
      <Router history={history}>
          <Route path="/**" component={Dashboard} />
      </Router>
);

export default App;