/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styled from "styled-components";

function InfoSection({saveCouncelling}) {

  return (
    <InfoContainer>
      <ImageWrapper>
        <InfoImage
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4dbea3abde4a68398ed4ec16b8763d4566f26fc4c9912c356d0639c3a566ec3e?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Counselling Image"
        />
      </ImageWrapper>
      <SubmitButton type="submit" onClick={(event)=>{
        saveCouncelling(event);
      }}>Submit</SubmitButton>
    </InfoContainer>
  );
}

const InfoContainer = styled.div`
  display: flex;
  margin-top: -66px;
  width: 100%;
  flex-direction: column;
  color: rgb(255, 255, 255);
  font: 600 16px Inter, sans-serif;
  @media (max-width: 991px) {
    margin-top: -44px;
  }
`;

const ImageWrapper = styled.figure`
  display: flex;
  margin-bottom: 17px;
  justify-content: flex-end;
`;

const InfoImage = styled.img`
  aspect-ratio: 1.01;
  object-fit: contain;
  object-position: center;
  width: 161px;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.09);
  max-width: 100%;
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  border: 1px solid rgb(239, 66, 54);
  background: linear-gradient(
    201deg,
    rgb(239, 66, 54) 2.29%,
    rgb(250, 169, 63) 108.55%
  );
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 18px;
  font-weight: 600;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default InfoSection;
