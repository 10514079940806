/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const CareerCounselling = () => {
  return (
    <StyledCareerCounselling>
      <FormSection>
        <FormTitle>Free Career Counselling</FormTitle>
        <FormField>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            placeholder="Enter your email"
            aria-label="Enter your email"
          />
        </FormField>
        <FormField>
          <Label htmlFor="phone">Phone</Label>
          <Input
            type="tel"
            id="phone"
            placeholder="+91 Phone number"
            aria-label="Enter your phone number"
          />
        </FormField>
      </FormSection>
      <ImageSection>
        <CounsellingImage
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f5ffc34d4431790484c5fd8575d282d0f90c6177cad03c80ab4cc789d61cf76?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Career Counselling"
        />
        <SubmitButton>Submit</SubmitButton>
      </ImageSection>
    </StyledCareerCounselling>
  );
};

const StyledCareerCounselling = styled.section`
  border-radius: 7px;
  margin-top: 130px;
  width: 996px;
  max-width: 100%;
  padding: 0 42px 65px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
    flex-direction: column;
  }
`;

const FormSection = styled.form`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  font-family: Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FormTitle = styled.h2`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 21px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Label = styled.label`
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 7px;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  font-size: 16px;
  color: #667085;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -66px;
  @media (max-width: 991px) {
    margin-top: 20px;
    align-items: center;
  }
`;

const CounsellingImage = styled.img`
  aspect-ratio: 1.01;
  object-fit: contain;
  object-position: center;
  width: 161px;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.09);
  max-width: 100%;
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  border: 1px solid #ef4236;
  background: linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  margin-top: 17px;
  cursor: pointer;
`;

export default CareerCounselling;
