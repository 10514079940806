/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const RatingSection = () => (
  <RatingSectionStyled>
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/37740681dc378690c3a318792021f8bd3dba595b7e4a191277a84650ffeca608?apiKey=a6dad463439244ae86e3212633b4369a&"
      alt="Rating"
    />
    <div className="rating-number">5.5</div>
    <div className="rating-reviews">(5k reviews)</div>
  </RatingSectionStyled>
);

const RatingSectionStyled = styled.section`
  display: flex;
  margin-top: 14px;
  align-items: center;
  gap: 9px;
  img {
    aspect-ratio: 2.5;
    object-fit: cover;
    object-position: center;
    width: 86px;
  }
  .rating-number {
    color: #7f56d9;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
  }
  .rating-reviews {
    color: #344054;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    line-height: 128.3%;
  }
`;

export default RatingSection;
