/**
 * This code was generated by Builder.io.
 */
import React from "react";
import LoginForm from "./LoginForm";
import { GlobalStyle, LoginPageStyles } from "./styles";

const LoginPage = () => {
  return (
    <>
      <GlobalStyle />
      <main className="login-container">
        <div className="login-content">
          <section className="login-form-column">
            <div className="login-form-wrapper">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/98095fc163d33ec3ff5f28602cd0fc623d5769ccd4546486cf55f811d56e01e4?apiKey=a6dad463439244ae86e3212633b4369a&"
                alt="Company logo"
                className="logo"
              />
              <div className="form-content">
                <header className="form-header">
                  <h1 className="form-title">Login</h1>
                  <p className="form-subtitle">To view events and courses</p>
                </header>
                <LoginForm />
              </div>
            </div>
          </section>
          <aside className="image-column">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/66c06df1db2a76328fc8fbbd28001c76918b2b8d0afed0faf5344487fa760361?apiKey=a6dad463439244ae86e3212633b4369a&"
              alt="Decorative hero image"
              className="hero-image"
            />
          </aside>
        </div>
        <style jsx>{LoginPageStyles}</style>
      </main>
    </>
  );
};

export default LoginPage;
