/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import ProgramCard from "./ProgramCard";

const programsData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1b7fb3210f470d8ff8b0a4ec72c0f3e4c54cf7ebc8a167842c42a9884ea94f65?placeholderIfAbsent=true&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Training",
    description:
      "Our training program enhances professionals' skills and competencies through advanced training, hands-on practice, and mentorship. Tailored for all experience levels, it focuses on improving job performance and supporting growth, with assessments to ensure progress and effective outcomes. Our goal is to equip your team with the tools to excel and drive career success.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4445388ca8a5e6466c75b131cece1343500baa0866cba0db20ff95a4ecc6944?placeholderIfAbsent=true&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Mentorship",
    description:
      "Our mentorship program pairs individuals with experienced mentors to foster growth through personalized guidance and real-world insights. Tailored for all experience levels, it provides targeted advice, builds confidence, and accelerates career advancement, empowering your team to reach their full potential.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/bfcef6b278878b3cc5df5bb876399c4cd916d35963be45b958653133274bfe15?placeholderIfAbsent=true&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Softskill development",
    description:
      "Our soft skills development program enhances critical interpersonal and communication abilities through workshops, role-playing, and real-world scenarios. Tailored for all career stages, it builds teamwork, leadership, and problem-solving skills, helping participants navigate workplace challenges confidently. With regular feedback, the program ensures continuous improvement and fosters a positive work environment, collaboration, and overall performance.",
  },
];

function ProgramsOffered() {
  return (
    <ProgramsSection>
      <ProgramsContainer>
        <SectionTitle>Programs Offered</SectionTitle>
        <ProgramsGrid>
          {programsData.map((program, index) => (
            <ProgramCard key={index} {...program} />
          ))}
        </ProgramsGrid>
      </ProgramsContainer>
    </ProgramsSection>
  );
}

const ProgramsSection = styled.section`
  background-color: #f6803c;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 65px 80px 192px;
  margin-top: 73px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px 100px;
  }
`;

const ProgramsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1010px;
  flex-direction: column;
  margin: 0 0 -38px 20px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

const SectionTitle = styled.h2`
  color: #fff;
  text-align: center;
  align-self: center;
  font: 500 34px "Encode Sans Semi Condensed", -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const ProgramsGrid = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 92px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin-top: 40px;
  }
`;

export default ProgramsOffered;
