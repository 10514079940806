/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const EventCard = ({
  date,
  title,
  description,
  speaker,
  attendees,
  status,
  seatsLeft,
}) => {
  return (
    <StyledEventCard>
      <EventImage
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0c7ed178bbe2222ad74e49c8720547959c17c7ed312b78a875a8084b728282a?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt={title}
      />
      <EventContent>
        <EventDate>{date}</EventDate>
        <EventTitle>{title}</EventTitle>
        <EventDescription>{description}</EventDescription>
        <SpeakerInfo>
          <SpeakerAvatar
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc6154a432848cebad7ada76b4c6eb390f890512682fdd0d6b85b378041c3cfb?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            alt={speaker.name}
          />
          <SpeakerDetails>
            <SpeakerName>{speaker.name}</SpeakerName>
            <SpeakerRole>{speaker.role}</SpeakerRole>
          </SpeakerDetails>
          <AttendeeCount>{attendees}</AttendeeCount>
        </SpeakerInfo>
        {status === "open" && (
          <>
            <EnrollButton>Enroll now</EnrollButton>
            {seatsLeft && <SeatsLeft>{seatsLeft} seats left</SeatsLeft>}
          </>
        )}
        {status === "enrolled" && (
          <EnrolledButton>
            <EnrolledIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9219248012e6b5f040498f3450ddab132ded0182b830b011cb2deb7af5ff267?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              alt="Enrolled"
            />
            <EnrolledText>Enrolled</EnrolledText>
          </EnrolledButton>
        )}
        {status === "past" && (
          <ViewRecordingsButton>View Recordings</ViewRecordingsButton>
        )}
      </EventContent>
    </StyledEventCard>
  );
};

const StyledEventCard = styled.article`
  border-radius: 7px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  color: #072c50;
  width: 100%;
  padding: 1px 0 37px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

const EventImage = styled.img`
  aspect-ratio: 2.07;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 4px;
`;

const EventContent = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 0 18px;
`;

const EventDate = styled.p`
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  @media (max-width: 991px) {
    margin-left: 7px;
  }
`;

const EventTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin-top: 11px;
  @media (max-width: 991px) {
    margin-left: 7px;
  }
`;

const EventDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-top: 11px;
  @media (max-width: 991px) {
    margin-left: 7px;
  }
`;

const SpeakerInfo = styled.div`
  display: flex;
  margin-top: 26px;
  width: 272px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    margin-left: 7px;
  }
`;

const SpeakerAvatar = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 44px;
  border-radius: 50%;
`;

const SpeakerDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

const SpeakerName = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
`;

const SpeakerRole = styled.p`
  font-size: 12px;
  font-weight: 400;
  align-self: start;
  margin-top: 5px;
`;

const AttendeeCount = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin: auto 0;
`;

const EnrollButton = styled.button`
  border-radius: 8px;
  border: 1px solid #7f56d9;
  background: #7f56d9;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  width: 100%;
  margin-top: 17px;
  cursor: pointer;
`;

const SeatsLeft = styled.p`
  color: #ef4236;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  align-self: center;
  margin-top: 11px;
`;

const EnrolledButton = styled.button`
  border-radius: 8px;
  background-color: rgba(8, 160, 42, 0.1);
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding: 10px 18px;
  border: 1px solid rgba(8, 160, 42, 0.27);
  color: #08a02a;
  font-size: 16px;
  font-weight: 600;
  margin-top: 17px;
  cursor: pointer;
`;

const EnrolledIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
`;

const EnrolledText = styled.span`
  align-self: stretch;
  margin: auto 0;
`;

const ViewRecordingsButton = styled.button`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  width: 100%;
  margin-top: 17px;
  cursor: pointer;
`;

export default EventCard;
