/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Card>
      <Icon src={icon} alt={title} />
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1;
  min-width: 240px;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  flex: 1;
`;

const Title = styled.h3`
  color: var(--Gray-900, #101828);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const Description = styled.p`
  color: var(--Gray-600, #475467);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 0;
`;

export default FeatureCard;
