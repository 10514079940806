/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  max-width: 1215px;
  gap: 20px;
  font-size: 18px;
  color: #072c50;
  font-weight: 500;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Logo = styled.img`
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
  width: 205px;
  max-width: 100%;
`;

const Navigation = styled.nav`
  display: flex;
  margin-top: 7px;
  gap: 40px;
`;

const NavLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Logo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b5439913e76142660b4c3b83ea1b07ccc92252d95fa458cf8a8487e0378ed68?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Company logo"
      />
      <Navigation>
        <NavLink href="#about">About</NavLink>
        <NavLink href="#services">Services</NavLink>
        <NavLink href="#products">Products</NavLink>
        <NavLink href="#contact">Contact</NavLink>
        <NavLink href="#faq">FAQ</NavLink>
      </Navigation>
    </HeaderWrapper>
  );
};

export default Header;
