/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const HeroSection = () => {
  return (
    <HeroWrapper>
      <HeroContent>
        <HeroTitle>
        A platform built you full stack development champion and get your first job through strategic training.
        </HeroTitle>
        <HeroDescription>
          Technology and the world of work change fast — with us, you're faster.
          Get the skills to achieve goals and stay competitive.
        </HeroDescription>
        <ButtonGroup>
          <PrimaryButton onClick={()=>{window.location.href = "/courses";}}>Explore Courses</PrimaryButton>
        </ButtonGroup>
        <TrustPilot
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/baf166e5a6c3e97f9302d6d30cefcdf5c08e8637660e1f875660c3624d7f7d52?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="TrustPilot"
        />
        <RatingWrapper>
          <RatingStars
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/37740681dc378690c3a318792021f8bd3dba595b7e4a191277a84650ffeca608?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            alt="Rating Stars"
          />
          <RatingInfo>
            <RatingScore>5.5</RatingScore>
            <ReviewCount>(5k reviews)</ReviewCount>
          </RatingInfo>
        </RatingWrapper>
      </HeroContent>
      <HeroImage
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e85eb49db3e7f8087a6d24d0de90cb3b1f0b1c2cce75817ba7e29b907191d091?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Hero Image"
      />
    </HeroWrapper>
  );
};

const HeroWrapper = styled.section`
  display: flex;
  margin-top: 35px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const HeroContent = styled.div`
  border-radius: 6px;
  background-color: #f6f7f9;
  display: flex;
  width: 56%;
  flex-direction: column;
  align-items: center;
  font-family: Inter, sans-serif;
  margin: 0 auto;
  padding: 37px 75px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 19px;
    padding: 0 20px;
  }
`;

const HeroTitle = styled.h1`
  color: #072c50;
  text-align: center;
  align-self: start;
  margin-left: 10px;
  font: 500 54px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const HeroDescription = styled.p`
  color: #344054;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  align-self: start;
  margin-top: 21px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 46px;
  width: 410px;
  max-width: 100%;
  gap: 19px;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Button = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px 18px;
  border: none;
  cursor: pointer;
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
  color: #fff;
`;

const SecondaryButton = styled(Button)`
  background-color: #fff;
  color: #072c50;
  border: 1px solid #d0d5dd;
`;

const TrustPilot = styled.img`
  aspect-ratio: 5.85;
  object-fit: contain;
  object-position: center;
  width: 158px;
  margin-top: 45px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  width: 198px;
  max-width: 100%;
  gap: 15px;
`;

const RatingStars = styled.img`
  aspect-ratio: 2.53;
  object-fit: contain;
  object-position: center;
  width: 86px;
`;

const RatingInfo = styled.div`
  display: flex;
  gap: 4px;
  flex: 1;
  margin: auto 0;
`;

const RatingScore = styled.div`
  color: #7f56d9;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
`;

const ReviewCount = styled.div`
  color: #344054;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  margin: auto 0;
`;

const HeroImage = styled.img`
  aspect-ratio: 0.86;
  object-fit: contain;
  object-position: center;
  width: 44%;
  border-radius: 6px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 19px;
  }
`;

export default HeroSection;
