/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styled from "styled-components";
import FormSection from "./FormSection";
import InfoSection from "./InfoSection";
import { init,saveCouncelling } from "../../../utility";

function CareerCounsellingForm() {

  init();
  const [councelling,setCouncelling] = React.useState({email:'',mobile_number:''});
  const onKeyUpHandler = (event,field_name) =>{
    let councellingObj = Object.assign({},councelling);
    councellingObj[field_name] = event.target.value.trim();
    setCouncelling(councellingObj);
  }
  const saveCouncellingCallback = (event) =>{
    event.preventDefault();
    event.stopPropagation();
    saveCouncelling(councelling).then(()=>{
      alert("Thanks, we will get back to you!");
      setCouncelling({email : '', mobile_number : ''});
    }).catch(()=>{
      alert("Sorry, could not able to process your request!");
    })
  }
  return (
    <Wrapper>
      <ContentWrapper>
        <FormColumn>
          <FormSection councelling={councelling} onKeyUpHandler={onKeyUpHandler}/>
        </FormColumn>
        <InfoColumn>
          <InfoSection saveCouncelling={saveCouncellingCallback}/>
        </InfoColumn>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 7px;
  margin-top: 47px;
  width: 996px;
  max-width: 100%;
  padding: 0 42px 65px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const ContentWrapper = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const FormColumn = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 72%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const InfoColumn = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 28%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export default CareerCounsellingForm;
