/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const ProgramCard = ({
  title,
  price,
  modules,
  duration,
  originalPrice,
  discountPrice,
  features,
  buttonLabel,
  scheduleDate,
  scheduleTime
}) => (
  <ProgramCardContainer>
    <CardHighlight />
    <CardContent>
      <CardTitle>{title}</CardTitle>
      <CardHeader>
        <CardDescription>Price: {price}</CardDescription>
        <CardDescriptionOriginal>{originalPrice}</CardDescriptionOriginal>
      </CardHeader>
      <CardText>{modules}</CardText>
      <CardDuration>Duration: {duration}</CardDuration>
      <CardDuration>Schedule: {scheduleDate} {scheduleTime}</CardDuration>
      <CardFeaturesGroup>
        {features.map((feature, index) => (
          <CardFeature key={index}>
            <img
              loading="lazy"
              src={feature.icon}
              alt={feature.alt}
              width="20px"
            />
            <span>{feature.label}</span>
          </CardFeature>
        ))}
      </CardFeaturesGroup>
      <CardButton>{buttonLabel}</CardButton>
    </CardContent>
  </ProgramCardContainer>
);

const CardHeader = styled.div`
    display:flex;
    padding:10px;
`;

const ProgramCardContainer = styled.article`
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  font-family: Inter, sans-serif;
  margin-top: 16px;
  width: 100%;
`;

const CardHighlight = styled.div`
  background-color: rgba(254, 184, 0, 1);
  height: 7px;
`;

const CardContent = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 26px;
  margin-top: 26px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const CardTitle = styled.h3`
  font: 500 24px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const CardDescription = styled.p`
  color: rgb(20 20 21);
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 17px;
`;

const CardDescriptionOriginal = styled.p`
  color: rgba(113, 127, 141, 1);
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 17px;
  margin-left:20px;
  text-decoration: line-through;
`;

const CardText = styled.p`
  color: rgba(52, 64, 84, 1);
  font-size: 16px;
`;

const CardFeaturesGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 37px;
`;

const CardFeature = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
`;

const CardPrices = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin-top: 16px;
`;

const CardOriginalPrice = styled.span``;

const CardDiscountPrice = styled.span``;

const CardDuration = styled.span`
  margin-top:20px;
`;

const CardButton = styled.button`
  border-radius: 8px;
  background-color: #7f56d9;
  color: #fff;
  font-weight: 600;
  padding: 13px 70px;
  margin-top: 48px;
  @media (max-width: 991px) {
    margin: 40px 0px;
    padding: 0 20px;
  }
`;

export default ProgramCard;
