/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Breadcrumb from "./Breadcrumb";
import CourseInfo from "./CourseInfo";
import CourseCurriculum from "./CourseCurriculum/CourseCurriculum";
import DeliveryFormat from "./DeliveryFormat";
import EnrollmentForm from "./EnrollmentForm";
import Footer from "../CommonComponents/Footer/Footer";
import CourseOverview from './CourseOverview/CourseOverview';
import {
  useLocation
} from "react-router-dom";

const CourseDetailPage = () => {

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const type = query.get("type");
  const course = query.get("id");

  return (
    <StyledPage>
      <Header />
      <main>
        <Breadcrumb type={type} course={course} />
        <CourseInfo type={type} course={course} />
        <section className="course-details">
          <CourseOverview type={type} course={course} />
        </section>
        <section className="curriculum-section">
         <CourseCurriculum type={type} course={course} />
        </section>
        <EnrollmentForm />
      </main>
      <Footer style={{paddingBottom:'35px'}}/>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export default CourseDetailPage;
