// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore"; 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2UxlDT-MKN06B1jMLBPJcQPwgc03bv3o",
  authDomain: "dhakar-shop.firebaseapp.com",
  databaseURL: "https://dhakar-shop.firebaseio.com",
  projectId: "dhakar-shop",
  storageBucket: "dhakar-shop.appspot.com",
  messagingSenderId: "814973905664",
  appId: "1:814973905664:web:77e415667ccf972c2721e5",
  measurementId: "G-0DBX0D9WFL"
};

// Initialize Firebase
let app = null;
let db = null;

const init = ()=>{
    app = initializeApp(firebaseConfig);
    db = getFirestore(app);
}

const saveContact = async (contact)=>{
    return await addDoc(collection(db, "contacts"), contact);
}

const saveCouncelling = async (councelling)=>{
    return await addDoc(collection(db, "councelling"), councelling);
}

const saveNewsLetter = async (newsLetter)=>{
    return await addDoc(collection(db, "NewsSubscription"), newsLetter);
}

export { init, saveContact, saveCouncelling, saveNewsLetter };
