/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styled from "styled-components";
import FeatureItem from "./FeatureItem";

export default function FeatureSection() {
  const features1 = [
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/d5d0569dd0c1a109bef6a399274ff75a222f1424a61ebc34405d90ea3ef5a8f3?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      title: "Hands-on Experience",
      description:
        "Gain practical skills through interactive projects and simulations, ensuring you’re job-ready.",
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/6ea34b34a0a55e002b8d4e2f5822b7743f675b1583108694b2333bb7e9313fa3?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      title: "Expert Mentors",
      description:
        "Learn from seasoned IT professionals who provide personalized guidance and industry insights.",
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/39321e3cbeb729f1d1d05e5dad0cdc961fb0ae04dd5587e030cd1909bd7a8d6b?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      title: "Comprehensive Materials",
      description:
        "Access high-quality resources, including video tutorials and catering to diverse learning styles.",
    },
  ];

  const features2 = [
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/eda3f927a68b440efb30c6cc11e8b86f17ecebfd3fe6fe551f73bd2e7e359ee7?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      title: "Job-Ready Skills",
      description:
        "Our courses align with current market demands, focusing on in-demand skills and tools.",
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/d6fbb6e4bba24fcb33922a2053dd7ac5477707e27b1d9adbf837a039da4b460c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      title: "Community Support",
      description:
        "Join a vibrant learning community with study groups, forums, and webinars to enhance your experience.",
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/ce8af7eb16e6bd557a7b8e7b4d993c6eb3b31d9c733ae93e0ff86ed053ca4652?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
      title: "Foundation for Future Technologies",
      description:
        "Benefit from resume building, interview preparation, and job placement support to secure your dream job.",
    },
  ];

  return (
    <MainSection>
      <Container>
        <Content>
          <HeadingAndSupportingText>
            <h2>Why Sodhark?</h2>
            <SupportingText>
              Our shared values keep us connected and guide us as one team.
            </SupportingText>
          </HeadingAndSupportingText>
        </Content>
      </Container>
      <Container2>
        <Content2>
          {features1.map((feature, index) => (
            <FeatureItem
              key={index}
              imgSrc={feature.imgSrc}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </Content2>
        <Content3>
          {features2.map((feature, index) => (
            <FeatureItem
              key={index}
              imgSrc={feature.imgSrc}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </Content3>
      </Container2>
    </MainSection>
  );
}

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1214px;
  margin: 0 auto;
  margin-top:130px;
`;

const Container = styled.div`
  display: flex;
  width: 1280px;
  max-width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 0 32px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeadingAndSupportingText = styled.div`
  display: flex;
  width: 768px;
  max-width: 100%;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  padding: 0 32px;
  h2 {
    width: 100%;
    font-size: 36px;
    color: #101828;
    font-weight: 600;
    letter-spacing: -0.72px;
    line-height: 1;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
`;

const SupportingText = styled.p`
  color: #475467;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Container2 = styled.div`
  display: flex;
  margin-top: 64px;
  width: 1280px;
  max-width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 0 32px;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Content2 = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 32px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Content3 = styled.div`
  display: flex;
  margin-top: 64px;
  width: 100%;
  align-items: start;
  gap: 32px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
