/**
 * This code was generated by Builder.io.
 */
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
  }
`;

export const LoginPageStyles = `
  .login-container {
    background-color: #f9fafb;
    padding: 80px 0 0 80px;
    overflow: hidden;
  }

  .login-content {
    display: flex;
    gap: 20px;
  }

  .login-form-column {
    display: flex;
    flex-direction: column;
    width: 37%;
  }

  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin: auto 0;
  }

  .logo {
    aspect-ratio: 4.02;
    object-fit: contain;
    object-position: center;
    width: 205px;
    max-width: 100%;
  }

  .form-content {
    display: flex;
    margin-top: 77px;
    flex-direction: column;
  }

  .form-header {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .form-title {
    color: #101828;
    letter-spacing: -0.56px;
    font: 600 28px/2 'Encode Sans Semi Condensed', -apple-system, Roboto, Helvetica, sans-serif;
  }

  .form-subtitle {
    color: #475467;
    margin-top: 18px;
    font: 400 18px Inter, sans-serif;
  }

  .login-form {
    display: flex;
    margin-top: 43px;
    width: 100%;
    flex-direction: column;
    font-family: Inter, sans-serif;
  }

  .form-fields {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .input-field {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .input-label {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 6px;
  }

  .input-wrapper {
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    background-color: #fff;
    display: flex;
    width: 100%;
    gap: 8px;
    padding: 12px 16px;
  }

  .input-content {
    align-self: stretch;
    flex: 1;
    min-width: 240px;
    width: 100%;
    gap: 8px;
    margin: auto 0;
    font-size: 16px;
    color: #072c50;
    font-weight: 400;
  }

  .login-button {
    display: flex;
    margin-top: 32px;
    width: 100%;
    flex-direction: column;
  }

  .button-wrapper {
    border-radius: 8px;
    display: flex;
    width: 100%;
    align-items: start;
  }

  .button-base {
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(77deg, #ef4236 25.76%, #faa93f 85.58%);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    min-width: 240px;
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    border: none;
  }

  .image-column {
    display: flex;
    flex-direction: column;
    width: 63%;
    margin-left: 20px;
  }

  .hero-image {
    aspect-ratio: 0.65;
    object-fit: contain;
    object-position: center;
    width: 100%;
    flex-grow: 1;
  }

  @media (max-width: 991px) {
    .login-container {
      padding-left: 20px;
    }

    .login-content {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }

    .login-form-column,
    .image-column {
      width: 100%;
      margin-left: 0;
    }

    .login-form-wrapper {
      margin-top: 40px;
    }

    .form-content {
      margin-top: 40px;
    }

    .form-title {
      max-width: 100%;
      white-space: normal;
    }

    .login-form {
      margin-top: 40px;
      white-space: normal;
    }

    .hero-image {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;
