/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

function Header() {
  return (
    <HeaderWrapper>
      <HeaderContent>
        <Logo
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e53747486fa382cea7d6a4e93c9a6799eb5cd2eead87616dc41a38e3483aa8d?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Company Logo"
        />
        <Navigation>
          <NavItem href="/">Home</NavItem>
          <NavItem href="/technologies">Technologies</NavItem>
          <NavItem href="/programmes">Programmes</NavItem>
          <NavItem href="/courses">Courses</NavItem>
          {/* <NavItem href="/events">Events</NavItem> */}
          <NavItem href="/contactus">Contact Us</NavItem>
        </Navigation>
      </HeaderContent>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 1215px;
  gap: 20px;
  padding-bottom: 17px;
  color: #072c50;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Logo = styled.img`
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
  width: 205px;
  border-radius: 0;
  max-width: 100%;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const NavItem = styled.a`
  cursor: pointer;
  color:black;
  text-decoration:none;
  font-size:14px;
`;

export default Header;
