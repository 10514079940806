/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import styled from "styled-components";
import {init,saveNewsLetter} from '../../utility';

const NewsletterWrapper = styled.section`
  border-radius: 25px;
  background-color: #f4ebff;
  display: flex;
  margin-top: 22px;
  width: 1307px;
  max-width: 100%;
  align-items: start;
  font-family: Inter, sans-serif;
  padding: 35px 64px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const NewsletterIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 101px;
  z-index: 10;
  margin-right: -70px;
  max-width: 100%;
`;

const NewsletterContent = styled.div`
  display: flex;
  margin-top: 22px;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  padding: 0 92px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 22px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const TextContent = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  color: #072c50;
  justify-content: start;
  width: 471px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const EmailCapture = styled.form`
  align-self: start;
  display: flex;
  min-width: 240px;
  align-items: start;
  gap: 16px;
  font-size: 16px;
  justify-content: start;
`;

const EmailInput = styled.input`
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  width: 280px;
  gap: 8px;
  overflow: hidden;
  color: #667085;
  font-weight: 400;
  padding: 10px 14px;
`;

const SubscribeButton = styled.button`
  border-radius: 8px;
  border: 1px solid #ef4236;
  background: linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  padding: 10px 18px;
  cursor: pointer;
`;

function Newsletter() {

  const [newsLetter,setNewsLetter] = useState({email:''});
  init();

  return (
    <NewsletterWrapper>
      <NewsletterIcon
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/728e20f1ebaf46563d7ca51241a21257792adaee76af82cd05361d34ea6e3f51?placeholderIfAbsent=true&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Newsletter icon"
      />
      <NewsletterContent>
        <ContentWrapper>
          <TextContent>
            <Title>Join our newsletter</Title>
            <Subtitle>
              We'll send you a friendly letter once a week, and we promise no
              spam.
            </Subtitle>
          </TextContent>
          <EmailCapture onSubmit={(e) => e.preventDefault()}>
            <EmailInput
              type="email"
              placeholder="Enter your email"
              aria-label="Enter your email"
              defaultValue={newsLetter.email.trim()} onKeyUp={(event)=>{
                let newsLetterObj = Object.assign({},newsLetter);
                newsLetterObj.email = event.target.value.trim();
                setNewsLetter(newsLetterObj);
              }}
            />
            <SubscribeButton type="submit" onClick={(event)=>{
              event.preventDefault();
              event.stopPropagation();
              saveNewsLetter(newsLetter).then(()=>{
                alert("Thanks for subscribing our news letter, You will be updated with latest technologies.");
                setNewsLetter({email : ''});
              }).catch(()=>{
                alert("failed to save");
              })
            }}>Subscribe</SubscribeButton>
          </EmailCapture>
        </ContentWrapper>
      </NewsletterContent>
    </NewsletterWrapper>
  );
}

export default Newsletter;
