/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const FormGroup = ({ label, type = "text", placeholder }) => (
  <FormGroupContainer>
    <Label htmlFor={label}>{label}</Label>
    <Input
      id={label}
      type={type}
      placeholder={placeholder}
      aria-label={label}
    />
  </FormGroupContainer>
);

const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 24px;
`;

const Label = styled.label`
  color: var(--Gray-700, #344054);
  font-size: 14px;
  font-weight: 500;
`;

const Input = styled.input`
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--White, #fff);
  color: var(--Gray-500, #667085);
  font-size: 16px;
  width: 100%;
`;

export default FormGroup;
