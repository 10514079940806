/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Footer = () => (
  <FooterContainer>
    <Prompt>Already have an account?</Prompt>
    <LoginLink href="#">
      Login to account
      <ArrowIcon
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4397608b2a1e8ac0008d1e1f7a3058561368d067e55233e4c3a6275a6168fff0?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="arrow"
      />
    </LoginLink>
  </FooterContainer>
);

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 39px;
`;

const Prompt = styled.p`
  color: var(--Gray-600, #475467);
  font: 400 16px Inter, sans-serif;
`;

const LoginLink = styled.a`
  display: flex;
  align-items: center;
  margin-top: 14px;
  color: rgba(127, 86, 217, 1);
  font: 600 16px Inter, sans-serif;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const ArrowIcon = styled.img`
  margin-left: 8px;
  width: 20px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
`;

export default Footer;
