/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styled from "styled-components";
import FormField from "./FormField";

function FormSection({councelling,onKeyUpHandler}) {
  return (
    <FormContainer>
      <Title>Free Career Counselling</Title>
      <InputSection>
        <FormField
          label="Email"
          inputType="email"
          placeholder="Enter your email"
          value={councelling.email}
          field_name={'email'}
          onKeyUpHandler={onKeyUpHandler}
        />
        <FormField
          label="Phone Number"
          inputType="tel"
          placeholder="+91 Phone number"
          field_name={'mobile_number'}
          value={councelling.mobile_number}

          onKeyUpHandler={onKeyUpHandler}
        />
      </InputSection>
    </FormContainer>
  );
}

const FormContainer = styled.form`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  font-family: Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Title = styled.h2`
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  color:black;
`;

const InputSection = styled.div`
  display: flex;
  margin-top: 7px;
  gap: 26px;
  font-size: 16px;
  color: rgb(102, 112, 133);
  font-weight: 400;
  flex-wrap: wrap;
`;

export default FormSection;
