/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import NavigationItem from "./NavigationItem";
import TechnologiesSection from '../TechnologiesSection/TechnologiesSection';

const Header = () => {
  const navigationItems = [
    {
      "name" : "Home",
      "link" : "/"
    },
    {
      "name" : "About Us",
      "link" : "/about"
    },
    {
      "name" : "Programmes",
      "link" : "/programmes"
    }, 
    {
      "name" : "Courses",
      "link" : "/courses"
    },
    // {
    //   "name" : "Events",
    //   "link" : "/events"
    // },
    {
      "name" : "Contact Us",
      "link" : "/contactus"
    }
  ];

  return (
    <HeaderWrapper>
      <Logo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe93a89da763c4d0f09e6b7ccde2a24bb6e4a74b645634d341601a5a7019400a?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Company Logo"
      />
      <Navigation>
        {navigationItems.map((item, index) => (
          <NavigationItem key={index} text={item.name} link={item.link} />
        ))}
      </Navigation>
      <TechnologiesSection />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 1215px;
  gap: 20px;
  color: rgba(7, 44, 80, 1);
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Logo = styled.img`
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
  width: 205px;
  border-radius: 0;
  max-width: 100%;
`;

const Navigation = styled.nav`
  align-self: start;
  display: flex;
  margin-top: 7px;
  gap: 40px 44px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export default Header;
