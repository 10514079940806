/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Header from "./Header";
import NotFoundContent from "./NotFoundContent";
import Footer from "./Footer";
import { GlobalStyle } from "./GlobalStyle";

const NotFoundPage = () => {
  return (
    <main className="not-found-page">
      <GlobalStyle />
      <Header />
      <NotFoundContent />
      <Footer />
    </main>
  );
};

export default NotFoundPage;
