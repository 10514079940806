/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import ValueCard from "./ValueCard";

const valuesData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d5d0569dd0c1a109bef6a399274ff75a222f1424a61ebc34405d90ea3ef5a8f3?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Prioritize Our Team",
    description:
      "Recognize and support the needs of our employees to empower them to perform at their highest potential.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6ea34b34a0a55e002b8d4e2f5822b7743f675b1583108694b2333bb7e9313fa3?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Foster Excellence in Collaboration",
    description:
      "Maintain transparency and authenticity. Trust your colleagues to elevate each other through open, honest, and kind interactions.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/39321e3cbeb729f1d1d05e5dad0cdc961fb0ae04dd5587e030cd1909bd7a8d6b?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Commitment to Excellence",
    description:
      "We uphold quality and integrity in all aspects of our work, without compromise.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/eda3f927a68b440efb30c6cc11e8b86f17ecebfd3fe6fe551f73bd2e7e359ee7?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Don't #!&$ the customer",
    description:
      "Understand customers' stated and unstated needs. Make them wildly successful.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d6fbb6e4bba24fcb33922a2053dd7ac5477707e27b1d9adbf837a039da4b460c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Do the impossible",
    description:
      'Be energized by difficult problems. Revel in unknowns. Ask "Why?", but always question, "Why not?"',
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ce8af7eb16e6bd557a7b8e7b4d993c6eb3b31d9c733ae93e0ff86ed053ca4652?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    title: "Sweat the small stuff",
    description:
      "We believe the best products come from the best attention to detail. Sweat the small stuff.",
  },
];

function ValuesSection() {
  return (
    <ValuesSectionWrapper>
      <SectionHeader>
        <Subheading>Our values</Subheading>
        <Heading>How we work at Sodhark</Heading>
        <SupportingText>
          Our shared values keep us connected and guide us as one team.
        </SupportingText>
      </SectionHeader>
      <ValuesContainer>
        {valuesData.map((value, index) => (
          <ValueCard key={index} {...value} />
        ))}
      </ValuesContainer>
    </ValuesSectionWrapper>
  );
}

const ValuesSectionWrapper = styled.section`
  align-items: center;
  background-color: #f9fafb;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-family: Inter, sans-serif;
  text-align: center;
  justify-content: start;
  padding: 96px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SectionHeader = styled.header`
  display: flex;
  width: 768px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Subheading = styled.p`
  color: #ef4236;
  font-size: 16px;
  font-weight: 600;
`;

const Heading = styled.h2`
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.72px;
  margin-top: 12px;
`;

const SupportingText = styled.p`
  color: #475467;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
`;

const ValuesContainer = styled.div`
  display: flex;
  margin-top: 64px;
  width: 1280px;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  padding: 0 32px;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

export default ValuesSection;
