/**
 * This code was generated by Builder.io.
 */
import styled from "styled-components";

export const InputField = styled.div`
  display: flex;
  min-height: 44px;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
`;

export const InputBase = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

export const InputLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

export const InputWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  justify-content: start;
  padding: 10px 14px;
`;

export const InputContent = styled.input`
  align-self: stretch;
  flex: 1;
  min-width: 240px;
  width: 100%;
  border: none;
  outline: none;
`;
