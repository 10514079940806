/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import AboutSection from "./AboutSection";
import ValuesSection from "./ValuesSection";
import TeamSection from "./TeamSection";
import ContactForm from "./ContactForm";
import Footer from "../CommonComponents/Footer/Footer";

function AboutUs() {
  return (
    <AboutUsWrapper>
      <Header />
      <AboutSection />
      <ValuesSection />
      {/* <TeamSection /> */}
      <ContactForm />
      <Footer />
    </AboutUsWrapper>
  );
}

const AboutUsWrapper = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 35px 0 35px;
`;

export default AboutUs;
