/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Header = () => (
  <HeaderContainer>
    <Logo
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/56955c007392e43942e7ea5cdec94af7a16e137f355d4db36f4e90e2787bdc17?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
      alt="Logo"
    />
    <TitleGroup>
      <Title>Sign up</Title>
      <Subtitle>To upskill and reskill for the tech world</Subtitle>
    </TitleGroup>
  </HeaderContainer>
);

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 205px;
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
`;

const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 66px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Title = styled.h1`
  color: var(--Gray-900, #101828);
  font: 600 28px/2 Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  letter-spacing: -0.56px;
`;

const Subtitle = styled.p`
  margin-top: 18px;
  color: var(--Gray-600, #475467);
  font: 400 18px Inter, sans-serif;
`;

export default Header;
