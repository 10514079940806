/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const CheckboxGroup = () => (
  <CheckboxContainer>
    <Checkbox>
      <input type="checkbox" id="privacyPolicy" />
      <Label htmlFor="privacyPolicy">
        <span className="sr-only">You agree to our friendly</span>
        <span> You agree to our friendly </span>
        <UnderlinedText>privacy policy</UnderlinedText>
        <span>.</span>
      </Label>
    </Checkbox>
  </CheckboxContainer>
);

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  color: var(--Gray-600, #475467);
  font: 400 16px/24px Inter, sans-serif;
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

export default CheckboxGroup;
