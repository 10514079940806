/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const ContactForm = () => (
  <FormContainer>
    <Avatar>
      <AvatarImage
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9221368642deeb630d51a07358741ef596a4826e48bda7dfef3154650c439fc?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Counselling Avatar"
      />
    </Avatar>
    <FormContent>
      <Title>Free Career Counselling</Title>
      <Subtitle>We are happy to help you 24/7</Subtitle>
      <Divider />
      <Form>
        <InputField
          type="email"
          placeholder="Enter your email"
          aria-label="Enter your email"
        />
        <PhoneInputContainer>
          <CountryDropdown tabIndex="0" role="listbox">
            <span>IN</span>
            <DropdownIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9f288935232c2b601ff5bd7f94908d897dfaab83742491521bc37030849a2b7c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              alt="Arrow Icon"
            />
          </CountryDropdown>
          <PhoneNumberInput
            type="tel"
            placeholder="+91 Enter Phone Number"
            aria-label="Enter Phone Number"
          />
        </PhoneInputContainer>
        <SubmitButton type="submit">Submit</SubmitButton>
      </Form>
    </FormContent>
  </FormContainer>
);

const FormContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled.div`
  background-color: rgba(241, 236, 251, 1);
  border-radius: 50%;
  width: 71px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 31px;
`;

const AvatarImage = styled.img`
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.09);
  border-radius: 50%;
`;

const FormContent = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 7px;
  background-color: #fff;
  padding: 29px 20px;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: rgba(0, 0, 0, 1);
  text-align: center;
  font: 500 20px "Encode Sans Semi Condensed", sans-serif;
  margin: 0;
`;

const Subtitle = styled.p`
  color: var(--Gray-500, #667085);
  text-align: center;
  font: 400 16px/1.5 "Inter", sans-serif;
  margin-top: 9px;
`;

const Divider = styled.hr`
  margin: 15px 0;
  border: 0;
  border-top: 1px solid rgba(228, 231, 236, 1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  color: var(--Gray-500, #667085);

  &:focus {
    outline: none;
    border-color: var(--Brand-600, #7f56d9);
  }
`;

const PhoneInputContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CountryDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 16px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  border-right: none;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  cursor: pointer;
`;

const DropdownIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const PhoneNumberInput = styled(InputField)`
  flex: 1;
  border-radius: 0 8px 8px 0;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px 18px;
  border: 1px solid var(--Brand-600, #7f56d9);
  border-radius: 8px;
  background-color: var(--Brand-600, #7f56d9);
  color: #fff;
  font: 600 16px "Inter", sans-serif;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default ContactForm;
