/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const AboutSection = () => (
  <AboutSectionStyled>
    <img
      className="about-image"
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ae186551174f46fadddf2bad5d4dc6fdf9d7ca2be904cd749ef193ec420716fd?apiKey=a6dad463439244ae86e3212633b4369a&"
      alt="About"
    />
    <nav className="navigation-menu">
      <a className="nav-link" href="#about">
        About
      </a>
      <a className="nav-link" href="#courses">
        Courses
      </a>
      <a className="nav-link" href="#contact">
        Contact
      </a>
    </nav>
  </AboutSectionStyled>
);

const AboutSectionStyled = styled.section`
  display: flex;
  width: 100%;
  max-width: 1214px;
  gap: 20px;
  font-size: 18px;
  color: #072c50;
  font-weight: 500;
  text-align: center;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  .about-image {
    aspect-ratio: 4;
    object-fit: cover;
    object-position: center;
    width: 205px;
  }
  .navigation-menu {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 0 20px;
  }
  .nav-link {
    font-family: Inter, sans-serif;
    text-decoration: none;
    color: inherit;
  }
`;

export default AboutSection;
