/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Header = () => (
  <HeaderContainer>
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f490bb0734284a7b83ec353b1827da8684e6fa85b09ac5568c954d3c048c389?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
      alt="Company Logo"
      width="205px"
    />
    <HeaderNav>
      <HeaderLink href="/">Home</HeaderLink>
      <HeaderLink href="/about">About Us</HeaderLink>
      <HeaderLink href="/technologies">Technologies</HeaderLink>
      <HeaderLink href="/courses">Courses</HeaderLink>
      {/* <HeaderLink href="/events">Events</HeaderLink> */}
      <HeaderLink href="/contactus">Contact Us</HeaderLink>
    </HeaderNav>
  </HeaderContainer>
);

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  max-width: 1215px;
  gap: 20px;
  color: rgba(7, 44, 80, 1);
  white-space: nowrap;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
  padding-top:30px;
`;

const HeaderNav = styled.nav`
  display: flex;
  align-self: start;
  margin-top: 7px;
  gap: 40px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const HeaderLink = styled.a`
  flex-grow: 1;
  color:black;
  font-size:14px;
  text-decoration:none;
`;

export default Header;
