/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const TechnologyImage = () => {
  return (
    <StyledImage
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e26084e41a17372477551659d0951dff343ecdf00a55016f01515f815ad62e95?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
      alt="Technology illustration"
    />
  );
};

const StyledImage = styled.img`
  aspect-ratio: 1.07;
  object-fit: contain;
  object-position: center;
  width: 318px;
  max-width: 100%;
`;

export default TechnologyImage;
