/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import ContactForm from "./ContactForm";
import Newsletter from "./Newsletter";
import Footer from "../CommonComponents/Footer/Footer";

const ContactUsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding: 39px 0;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1246px;
  margin: 78px 0 0 13px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ContentGrid = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ContactImage = styled.img`
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 0;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const MainComponent = styled.div`
  align-items:center;
`;

const PhysicalContactDetails = styled.div`

`;

const Address = styled.div`

`;

function ContactUs() {
  return (
    <MainComponent>
    <ContactUsWrapper>
      <Header />
      <MainContent>
        <ContentGrid>
          <FormColumn>
            <ContactForm />
          </FormColumn>
          <ImageColumn>
            <ContactImage
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbddea173623d99903d29688b879af1ccaaf3b4269d9d0611e6c88314c59a760?placeholderIfAbsent=true&apiKey=a6dad463439244ae86e3212633b4369a"
              alt="Contact illustration"
            />
          </ImageColumn>
        </ContentGrid>
      </MainContent>
      <PhysicalContactDetails>
        <Address>
          237, Giriraj Nagar, Sumer Nagar, Sanganer, Jaipur, Rajasthan 302020
        </Address>
        <Address>

        </Address>
      </PhysicalContactDetails>
      <Newsletter />
    </ContactUsWrapper>
    <Footer style={{paddingBottom:'35px'}}/>
    </MainComponent>
  );
}

export default ContactUs;
