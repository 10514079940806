/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import TechStack from "./TechStack";
import FeatureCard from "./FeatureCard";
import TechLogos from "./TechLogos";
import LogoGroup from "./LogoGroup";
import Header from './Header/Header';
import FeatureSection from './FeatureSection';
import FormComponent from './FormComponent';
import Footer from '../CommonComponents/Footer/Footer';

const TechnologySection = () => {
  return (
    <Section>
      <Header />
      <Container>
        <TechStack
          subheading="Tech Stack"
          heading="Technologies We Teach"
          description="Explore the Diverse Technologies We Teach to Empower Your Software Development Skills"
        />
        <FeatureGrid>
          <FeatureCard
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/63f085e0200bf46b921633a5122008d5d7dc08298280dd678e56d22945ea9090?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            title="Frameworks"
            description="Pre-built sets of tools and libraries that streamline software development with reusable components and best practices."
          />
          <FeatureCard
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/3957aa351e48ed8087a2fa7170780fde21600b6a500cdc2fbc1432917b3e5fdf?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            title="Frontend Technologies"
            description="Technologies used to build the client side of web applications, focusing on what users see and interact with on their browsers."
          />
        </FeatureGrid>
        <TechLogos />
        <FeatureColumns>
          <Column>
            <FeatureCard
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/15efc4e2e03ff479001819ada10eab263db7ad3908dfa590f2adb79baabea373?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              title="Backend Technologies"
              description="Technologies used to build the server side of web applications, handling logic, database interactions, user authentication, and server configuration."
            />
            <LogoGroup
              logos={[
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/7424ce46645500ff8b5ce867fabc4b566d83fbfd68e232a3c024e1674f7b36c2?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Backend Tech 1",
                },
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4c475b36d2ad712969f206bfc6bc5b4c51bb0ec6c99dc0fd923b22bb7ee36c78?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Backend Tech 2",
                },
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/2a274047e2a99ac6b191f4b5488a6ba2dbaa21e89a24f93fd0795265adb58e89?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Backend Tech 3",
                },
              ]}
            />
            <FeatureCard
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d935fa360feb7af1043dfcca2af96a5305bdd19e903f031158ba6108f99ca190?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              title="Build & Deployment"
              description="Processes involved in preparing code for production and deploying it to servers."
            />
            <LogoGroup
              logos={[
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/489009f16ff5bbbca0abe70207b7d3cb20c5f9fd0c1b3e5779e5e8fb93d3bc9b?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Build Tool 1",
                },
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/7958015d959cc13ee86f6721d8bcba352e68164d0f9c9e390bc4aee510df9c6b?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Build Tool 2",
                },
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/6e8c0be9a78d0e9cd9e3957465ee465329f184d28f9d416e93e7fea206e0d1fe?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Build Tool 3",
                },
              ]}
            />
          </Column>
          <Column>
            <FeatureCard
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/c426c57301aa3f94f0985c1e4b3b5297b48d874091721847bbf465c86c6ed177?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              title="Database Technologies"
              description="Systems used to store, retrieve, and manage data in web applications."
            />
            <LogoGroup
              logos={[
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/dec6f2f40729ccdd5689ae51b9620c5360459bbefd420b544839d9e353d5a9f2?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Database 1",
                },
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/f5dafc9e5b75b3ed7b654f0d1d1a1c2b65fd10957f1d42151369031a21131baf?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Database 2",
                },
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/42e47545106a3112d6cbc354740c02e26efa713a6e2067b26abe85a81ada7c60?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Database 3",
                },
                {
                  src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ad33c39e3494b356f9e005dce12da21ccb4d0d110594fcb7194f78db4155e978?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
                  alt: "Database 4",
                },
              ]}
              subGroup={[2, 3]}
            />
            <FeatureCard
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/f11a5a8a2e058da37d2eb4610e0ae6f6f355a41319d1e47b055a59b0a388a4f0?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              title="Version Control"
              description="Systems that help track changes to code and manage multiple versions of a project."
            />
            <VersionControlLogo
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8e9abd709208c00f5fd8ae2a44a7d4048f65ef98835b49fa2c256ebe1860d87?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              alt="Version Control"
            />
          </Column>
        </FeatureColumns>
        <AdditionalFeatures>
          <Column>
            <FeatureCard
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/25659135e70591c966126088a9392f4cbed763a0508b4f3eedb918d8d452fc04?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              title="Base Foundation"
              description="Core computer science concepts that help in writing efficient and optimised code such as Data Structure & Algorithms and Software development standards."
            />
          </Column>
          <Column>
            <FeatureCard
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/09e8dfecdb2cfbfe93d90190d1f45f742c993471354c9aaf030e652a500930ca?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
              title="UI/UX"
              description="Create visually appealing and user-friendly interfaces for enhanced user experiences."
            />
          </Column>
        </AdditionalFeatures>
        <LogoGroup
          logos={[
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8dd8f70f2c37568ca3d8e0be7b0a07f65616212e842a4a100f6a8349b958d17e?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
              alt: "Additional Tech 1",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/99818eaf768b10436c4fbfaa258c2d8cfd96ca16f11aa91fc1dc4953ad9920bc?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
              alt: "Additional Tech 2",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc3de35e65e0478194bdcb4924844e95060d767f740fb6309bda886896b7540c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
              alt: "Additional Tech 3",
            },
          ]}
          className="bottom-logos"
        />
      </Container>
      <FeatureSection />
      <FormComponent />
      <Footer style={{paddingBottom:'35px'}}/>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  margin-top: 25px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1214px;
  margin: 0 auto;
`;

const FeatureGrid = styled.div`
  display: flex;
  width: 100%;
  max-width: 1201px;
  gap: 40px 56px;
  flex-wrap: wrap;
  margin: 88px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FeatureColumns = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 59px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const AdditionalFeatures = styled.div`
  display: flex;
  width: 100%;
  margin-top: 60px;
  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 40px;
  }
`;

const VersionControlLogo = styled.img`
  width: 84px;
  height: 84px;
  object-fit: contain;
  margin: 9px 0 0 68px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

export default TechnologySection;
