/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const SkillSection = () => (
  <SkillSectionStyled>
    <h2 className="section-title">Learn and Grow</h2>
    <div className="section-content">
      <article className="section-column">
        <h3 className="column-title">Experience</h3>
        <p className="column-description">
          Learn new knowledge and skills in a variety of ways, from engaging
          video lectures and dynamic graphics to data visualizations and
          interactive elements.
        </p>
      </article>
      <article className="section-column">
        <h3 className="column-title">Practice</h3>
        <p className="column-description">
          Demonstrating your knowledge is a critical part of learning. Sodark
          courses and programs provide a space to practice with quizzes, open
          response assessments, virtual environments, and more.
        </p>
      </article>
      <article className="section-column">
        <h3 className="column-title">Apply</h3>
        <p className="column-description">
          Learning on Sodark transforms how you think and what you can do, and
          translates directly into the real world—immediately apply your new
          capabilities in the context of your job.
        </p>
      </article>
    </div>
    <button className="explore-plans-button">Explore Plans</button>
  </SkillSectionStyled>
);

const SkillSectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 80px;
  background-color: #fff;
  .section-title {
    color: #072c50;
    font-family: "Encode Sans Semi Condensed", sans-serif;
    font-weight: 500;
    font-size: 34px;
    text-align: center;
  }
  .section-content {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 1235px;
    margin-top: 56px;
    align-self: center;
    flex-direction: row;
  }
  .section-column {
    display: flex;
    flex-direction: column;
    width: 33%;
  }
  .column-title {
    color: #072c50;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  .column-description {
    color: #344054;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 11px;
  }
  .explore-plans-button {
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    color: #344054;
    margin-top: 62px;
    padding: 10px 18px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    border: 1px solid rgba(208, 213, 221, 1);
  }
`;

export default SkillSection;
