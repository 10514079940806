/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styled from "styled-components";

export default function FeatureItem({ imgSrc, title, description }) {
  return (
    <FeatureItemWrapper>
      <FeatureImg loading="lazy" src={imgSrc} alt={title} />
      <FeatureText>
        <FeatureTitle>{title}</FeatureTitle>
        <FeatureDescription>{description}</FeatureDescription>
      </FeatureText>
    </FeatureItemWrapper>
  );
}

const FeatureItemWrapper = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
`;

const FeatureImg = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  align-self: center;
`;

const FeatureText = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const FeatureTitle = styled.h3`
  color: #101828;
  font-size: 20px;
  font-weight: 500;
`;

const FeatureDescription = styled.p`
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  align-self: center;
  margin-top: 8px;
`;
