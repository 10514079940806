/**
 * This code was generated by Builder.io.
 */
import styled from "styled-components";

export const MainHeading = styled.h2`
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  color:black;
`;

export const SubHeading = styled.label`
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-top: 21px;
`;

export const SecondaryLabel = styled.label`
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-top: 51px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
