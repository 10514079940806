/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import AboutSection from "./AboutSection";
import EmpowerSection from "./EmpowerSection";
import SkillSection from "./SkillSection";

const MainComponent = () => (
  <MainComponentStyled>
    <AboutSection />
    <section className="empower-container">
      <EmpowerSection />
    </section>
    <section className="skill-container">
      <SkillSection />
    </section>
  </MainComponentStyled>
);

const MainComponentStyled = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0 71px;
  .empower-container,
  .skill-container {
    width: 100%;
    max-width: 1248px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
`;

export default MainComponent;
