/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

function ProgramCard({ icon, title, description }) {
  return (
    <CardWrapper>
      <CardIcon src={icon} alt="" />
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardWrapper>
  );
}

const CardWrapper = styled.article`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  font-family: Inter, sans-serif;
  color: #fff;
  font-weight: 500;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const CardIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 58px;
`;

const CardTitle = styled.h3`
  font-size: 28px;
  margin-top: 28px;
`;

const CardDescription = styled.p`
  font-size: 18px;
  text-align: center;
  align-self: stretch;
  margin-top: 18px;
`;

export default ProgramCard;
