/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styled from "styled-components";

function FormField({ label, inputType, placeholder,value,onKeyUpHandler,field_name }) {
  return (
    <FieldWrapper>
      <Label htmlFor={label}>{label}</Label>
      <Input
        type={inputType}
        id={label}
        placeholder={placeholder}
        aria-label={label}
        defaultValue={value}
        onKeyUp={(event)=>{onKeyUpHandler(event,field_name)}}
      />
    </FieldWrapper>
  );
}

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
`;

const Label = styled.label`
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-top: 21px;
`;

const Input = styled.input`
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgb(208, 213, 221);
  background: rgb(255, 255, 255);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  width: 100%;
  justify-content: start;
  padding: 10px 14px;
  margin-top: 5px;
`;

export default FormField;
