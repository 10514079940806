/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  max-width: 1215px;
  gap: 20px;
  color: #072c50;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Logo = styled.img`
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
  width: 205px;
  border-radius: 0;
  max-width: 100%;
`;

const NavLinks = styled.nav`
  align-self: start;
  display: flex;
  margin-top: 7px;
  gap: 40px 44px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const NavLink = styled.a`
  flex-grow: 1;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-size:14px;
`;

function Header() {

  return (
    <HeaderWrapper>
      <Logo
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/62ac610257b4991ca1de474f45c3a424a7a34e4c92c18445becedb46f5577942?placeholderIfAbsent=true&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Company logo"
      />
      <NavLinks>
      <NavLink href="/">Home</NavLink>
        <NavLink href="/about">About Us</NavLink>
        <NavLink href="/technologies">Technologies</NavLink>
        <NavLink href="/programmes">Programmes</NavLink>
        <NavLink href="/courses">Courses</NavLink>
        {/* <NavLink href="/events">Events</NavLink> */}
      </NavLinks>
    </HeaderWrapper>
  );
}

export default Header;
