/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Header = () => (
  <HeaderContainer>
    <Logo
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/146158b4c71c9ae6141f4fba8a03b2b8a64b157912f9bb7d40e0d7e0c5741c98?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
      alt="Logo"
    />
    <Navigation>
      <NavItem href="/">Home</NavItem>
      <NavItem href="/about">About Us</NavItem>
      <NavItem href="/technologies">Technologies</NavItem>
      <NavItem href="/programmes">Programmes</NavItem>
      {/* <NavItem href="/events">Events</NavItem> */}
      <NavItem href="/contactus">Contact Us</NavItem>
    </Navigation>
  </HeaderContainer>
);

const HeaderContainer = styled.header`
  align-self: stretch;
  display: flex;
  width: 100%;
  gap: 20px;
  color: rgba(7, 44, 80, 1);
  white-space: nowrap;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Logo = styled.img`
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
  width: 205px;
  border-radius: 0px 0px 0px 0px;
  max-width: 100%;
`;

const Navigation = styled.nav`
  align-self: start;
  display: flex;
  margin-top: 7px;
  gap: 40px 44px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const NavItem = styled.a`
  flex-grow: 1;
  text-decoration:none;
  color:black;
  font-size:14px;
`;

export default Header;
