/**
 * This code was generated by Builder.io.
 */
import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  margin-top: -66px;
  flex-direction: column;
  color: var(--White, #fff);
  white-space: nowrap;
  font: 600 16px Inter, sans-serif;

  @media (max-width: 991px) {
    margin-top: -44px;
    white-space: initial;
  }
`;

export const StyledImage = styled.img`
  aspect-ratio: 1.01;
  object-fit: contain;
  object-position: center;
  width: 161px;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.09);
  align-self: end;
  max-width: 100%;
`;

export const StyledButton = styled.button`
  border-radius: 8px;
  align-self: start;
  display: flex;
  align-items: start;
  justify-content: start;
  margin-top: 17px;
  border: 1px solid var(--Sodark, #ef4236);
  background: var(
    --Sodark,
    linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%)
  );
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(
    --Sodark,
    linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%)
  );
  width: 100%;
  gap: 8px;
  overflow: hidden;
  flex: 1;
  padding: 10px 18px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;
