/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import PlanOptionsSection from "./PlanOptionsSection";
import ImageComponent from './ImageComponent/ImageComponent';

const EmpowerSection = () => (
  <EmpowerSectionStyled>
    <div className="section-content">
      <div className="section-group">
      <h2 className="section-title">
        Empower your organization through holistic upskilling and reskilling
        solutions.
      </h2>
      <p className="section-description">
        Technology and the world of work change fast — with us, you’re faster.
        Get the skills to achieve goals and stay competitive.
      </p>
      <PlanOptionsSection />
      </div>
      <div className="section-group1"> 
      <ImageComponent />
      </div>
    </div>
    
  </EmpowerSectionStyled>
);

const EmpowerSectionStyled = styled.section`

  .section-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 59px 75px 30px;
    border-radius: 6px;
    background-color: #f6f7f9;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
  .section-title {
    color: #072c50;
    font-family: "Encode Sans Semi Condensed", sans-serif;
    font-weight: 500;
    font-size: 54px;
    line-height: normal;
    text-align: center;
  }
  .section-description {
    color: #344054;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-top: 25px;
  }
  .section-group {
    display: block;
  }
  .section-group1 {
    display: block;
  }
`;

export default EmpowerSection;
