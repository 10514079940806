/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import CourseObjectives from "../CourseObjectives/CourseObjectives";
import ContactForm from "../ContactForm/ContactForm";

const CourseOverview = ({type, course}) => {
  return (
    <Main>
      <Container>
        <CourseSection>
          <CourseObjectives type={type} course={course} />
        </CourseSection>
        <FormSection>
          <ContactForm />
        </FormSection>
      </Container>
    </Main>
  );
};

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 107px;
  width: 100%;
  align-items:center;

  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 10px 0;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
  }
`;

const CourseSection = styled.section`
  flex: 61%;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FormSection = styled.section`
  flex: 39%;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export default CourseOverview;
