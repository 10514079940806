/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import TeamMember from "./TeamMember";

const teamMembers = [
  {
    name: "Sonu Dhakar",
    role: "Founder & CEO",
    description: "Tech Enthuastic and coder fueled by coffee",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0a914a78616464ffc786b69ee8ac4fd322845ec8ae28fab31054e32c05d62c69?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    socialIcon:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/74ba69aa3296273fbc446aa7da415367f9c18645e4b986eae7991b5f5029cc55?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
  },
  // Repeat the above object 5 more times with different socialIcon URLs
  {
    name: "Sonu Dhakar",
    role: "Founder & CEO",
    description: "Tech Enthuastic and coder fueled by coffee",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0a914a78616464ffc786b69ee8ac4fd322845ec8ae28fab31054e32c05d62c69?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    socialIcon:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/9e95445e4fa14c754b7c32ecb8d67fcca7662c4db26ab626963f64a586c01e90?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
  },
  {
    name: "Sonu Dhakar",
    role: "Founder & CEO",
    description: "Tech Enthuastic and coder fueled by coffee",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0a914a78616464ffc786b69ee8ac4fd322845ec8ae28fab31054e32c05d62c69?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    socialIcon:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/6c362d8545bf0f0cb2648ad7af9b2ca4af9ef92302a30f9bf6e04bc832aaf58a?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
  },
  {
    name: "Sonu Dhakar",
    role: "Founder & CEO",
    description: "Tech Enthuastic and coder fueled by coffee",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0a914a78616464ffc786b69ee8ac4fd322845ec8ae28fab31054e32c05d62c69?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    socialIcon:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c62c95b4de820cfa97b895e9caa0cf1540440736ac4daa41a84425cf4ffcfa3d?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
  },
  {
    name: "Sonu Dhakar",
    role: "Founder & CEO",
    description: "Tech Enthuastic and coder fueled by coffee",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0a914a78616464ffc786b69ee8ac4fd322845ec8ae28fab31054e32c05d62c69?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    socialIcon:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/1d0eab45ea81fdf24a0278c54ad6cfa6cead63ca5af7a46877151e2d0023d8b6?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
  },
  {
    name: "Sonu Dhakar",
    role: "Founder & CEO",
    description: "Tech Enthuastic and coder fueled by coffee",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0a914a78616464ffc786b69ee8ac4fd322845ec8ae28fab31054e32c05d62c69?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    socialIcon:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/4ccb90244bad7686dc0ca7ca2a1c90a9ed013d4d3fb9359022530bc2bfe9f0a0?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
  },
];

function TeamSection() {
  return (
    <TeamSectionWrapper>
      <SectionHeader>
        <Subheading>We're hiring!</Subheading>
        <Heading>Meet our team</Heading>
        <Description>
          Our philosophy is simple — hire a team of diverse, passionate people
          and foster a culture that empowers you to do your best work.
        </Description>
      </SectionHeader>
      <TeamGrid>
        {teamMembers.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </TeamGrid>
    </TeamSectionWrapper>
  );
}

const TeamSectionWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 22px 1px 114px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 4px;
    padding-bottom: 100px;
  }
`;

const SectionHeader = styled.header`
  display: flex;
  width: 768px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Inter, sans-serif;
  padding: 20px 0;
`;

const Subheading = styled.p`
  color: #0fc437;
  font-size: 16px;
  font-weight: 600;
`;

const Heading = styled.h2`
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.72px;
  margin-top: 12px;
`;

const Description = styled.p`
  color: #475467;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 20px;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 944px;
  max-width: 100%;
  margin-top: 16px;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

export default TeamSection;
