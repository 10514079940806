/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const ObjectiveItem = ({ text }) => (
  <ListItem>
    <CheckIcon
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8ff51e336ed7201878174b6cc1782440e5cfb48599857706d628775a1208c7da?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
      alt="Check Icon"
    />
    <Text>{text}</Text>
  </ListItem>
);

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-top: 20px;
  width: 100%;
`;

const CheckIcon = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 14px;
`;

const Text = styled.p`
  font-size: 18px;
  color: var(--Gray-600, #475467);
  font-weight: 400;
  line-height: 28px;
  margin: 0;
`;

export default ObjectiveItem;
