/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

function AboutSection() {
  return (
    <AboutSectionWrapper>
      <BackgroundImage
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/031bcecf70f18b9c5f349b4c046c8317cd9d486109bf2967a8b2de336a0894ad?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Background"
      />
      <ContentWrapper>
        <Content>
          <Subheading>About us</Subheading>
          <Heading>About the company</Heading>
          <SupportingText>
            Meet the Team Driving Innovation in Student Learning and Tech
            Solutions
          </SupportingText>
        </Content>
      </ContentWrapper>
    </AboutSectionWrapper>
  );
}

const AboutSectionWrapper = styled.section`
  position: relative;
  min-height: 329px;
  margin-top: 15px;
  width: 100%;
  text-align: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  background-color: rgba(1, 0, 76, 0.72);
  display: flex;
  min-height: 329px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 86px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 960px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const Subheading = styled.p`
  color: #faa93f;
  font: 600 16px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Heading = styled.h1`
  color: #fff;
  margin-top: 12px;
  font: 500 54px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const SupportingText = styled.p`
  color: #fff;
  margin-top: 24px;
  font: 400 20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default AboutSection;
