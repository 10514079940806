/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styled from "styled-components";
import Header from "./Header";
import FormGroup from "./FormGroup";
import CheckboxGroup from "./CheckboxGroup";
import Footer from "./Footer";

function SignUp() {
  return (
    <MainContainer>
      <SignUpSection>
        <Header />
        <Form>
          <FormGroup label="First name" placeholder="First name" />
          <FormGroup label="Last name" placeholder="Last name" />
          <FormGroup label="Email" type="email" placeholder="you@company.com" />
          <FormGroup label="Password" type="password" placeholder="••••••••" />
          <FormGroup
            label="Confirm Password"
            type="password"
            placeholder="••••••••"
          />
          <CheckboxGroup />
          <SubmitButton>Create Account</SubmitButton>
        </Form>
        <Footer />
      </SignUpSection>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(249, 250, 251, 1);
  padding: 35px 80px 92px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const SignUpSection = styled.section`
  display: flex;
  width: 860px;
  max-width: 100%;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SubmitButton = styled.button`
  margin-top: 32px;
  padding: 12px 20px;
  border-radius: 8px;
  background: linear-gradient(77deg, #ef4236 25.76%, #faa93f 85.58%);
  color: var(--White, #fff);
  font: 600 16px Inter, sans-serif;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
`;

export default SignUp;
