/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import RatingSection from "./RatingSection";

const PlanOptionsSection = () => (
  <PlanOptionsSectionStyled>
    <h3 className="section-title">Our Happy Students</h3>
    <RatingSection />
    <div className="button-container">
      <button className="individual-plans-button">Plans for Individuals</button>
      <button className="organization-plans-button">
        Plans for Organizations
      </button>
    </div>
  </PlanOptionsSectionStyled>
);

const PlanOptionsSectionStyled = styled.section`
  .section-title {
    color: #344054;
    font-family: "Uhudscript Demo", sans-serif;
    font-weight: 400;
    font-size: 29px;
    line-height: 83%;
    margin-top: 45px;
  }
  .button-container {
    display: flex;
    gap: 19px;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin-top: 48px;
  }
  .individual-plans-button {
    font-family: Inter, sans-serif;
    border-radius: 8px;
    background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    padding: 10px 18px;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
  .organization-plans-button {
    font-family: Inter, sans-serif;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    color: #072c50;
    padding: 10px 18px;
    border: 1px solid rgba(208, 213, 221, 1);
  }
`;

export default PlanOptionsSection;
