/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const TechnologyContent = () => {
  return (
    <ContentWrapper>
      <Title>Technologies</Title>
      <Description>Using best technologies to teach our students</Description>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  align-self: start;
  font: 54px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;

  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const Description = styled.p`
  margin-top: 32px;
  font: 24px/1 Inter, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default TechnologyContent;
