/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const TechLogos = () => {
  return (
    <Container>
      <LogoGroup>
        <Logo
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/108c621ce9611a1c33c5872f38788014d5a3cdc3fbdef0c88cff61579d678645?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Tech Logo 1"
        />
        <Logo
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8d093924e60545bde9bc3386696be48943d258c3b8ed03f3fda9f062afb8f03?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Tech Logo 2"
        />
        <Logo
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/19fcd86b13eaafb1c149127e2d4487922157571859a9f25091e5cfd070d997bd?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Tech Logo 3"
        />
        <Logo
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f2b1f67f45e12530790638bdc4787548af4fe4d429710290b73ba70f93649205?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Tech Logo 4"
        />
      </LogoGroup>
      <WideLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d3c99293dcaaa653c56ae3a9dcf3c2f37a02c45752ace050d0daa757df749ac4?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Wide Tech Logo"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 923px;
  max-width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 33px 0 0 79px;
`;

const LogoGroup = styled.div`
  display: flex;
  gap: 30px;
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
  object-fit: contain;
`;

const WideLogo = styled.img`
  width: 267px;
  object-fit: contain;
`;

export default TechLogos;
