/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";

const Header = () => {
  return (
    <HeaderWrapper>
      <Logo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/07751e796b7dab2fdf613381e707a374514c221830055d02752a4f6a951df173?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Company Logo"
      />
      <NavLinks>
      <NavItem href="/">Home</NavItem>
        <NavItem href="/about">About Us</NavItem>
        <NavItem href="/technologies">Technologies</NavItem>
        <NavItem href="/programmes">Programmes</NavItem>
        <NavItem href="/courses">Courses</NavItem>
        {/* <NavItem href="/events">Events</NavItem> */}
        <NavItem href="/contactus">Contact Us</NavItem>
      </NavLinks>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  gap: 20px;
  color: #072c50;
  white-space: nowrap;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Inter, sans-serif;
  margin-top:30px;
  padding-left:102px;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Logo = styled.img`
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
  width: 205px;
  border-radius: 0;
  max-width: 100%;
`;

const NavLinks = styled.nav`
  align-self: start;
  display: flex;
  margin-top: 7px;
  gap: 40px 20px;
  margin-right: 50px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const NavItem = styled.a`
  flex-grow: 1;
  cursor: pointer;
  color:black;
  text-decoration:none;
  font-size:14px;
`;

export default Header;
