/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import ObjectiveItem from "./ObjectiveItem";

const CourseObjectives = ({type,course}) => {
  const objectives = {
    'frontend' : {
      'react' : [
        "Understand the core principles and concepts of ReactJS",
        "Learn how to set up a development environment for ReactJS projects",
        "Master the component-based architecture of React and how to create reusable components",
        "Explore state management in React using hooks and context API",
        "Dive into routing and navigation in React applications",
        "Implement forms and handle user input efficiently",
        "Integrate with external APIs and manage data fetching in React",
        "Learn best practices for structuring and organizing React applications",
        "Explore advanced topics such as performance optimization and testing in React",
        "Build real-world projects to apply your knowledge and skills effectively"
      ],
      'javascript' : [
        "Understand the fundamentals of JavaScript syntax, variables, and data types",
        "Learn how to manipulate the Document Object Model (DOM) to interact with HTML elements",
        "Master control structures and functions to create dynamic and interactive web applications",
        "Explore advanced topics such as asynchronous programming, closures, and object-oriented programming (OOP)",
        "Develop practical skills through hands-on coding exercises and projects",
        "Gain proficiency in using JavaScript frameworks and libraries such as React.js and Node.js"
      ],
    'jquery' : [
        "DOM Manipulation: Selecting elements, modifying content, and manipulating CSS.",
        "Event Handling: Binding, unbinding, and custom events.",
        "Animations and Effects: Built-in effects, custom animations, and chaining.",
        "AJAX: Making requests, handling responses, and error management.",
        "Plugins: Using and creating jQuery plugins.",
        "Performance Optimization: Efficient selectors and minimizing reflows.",
        "Best Practices and Debugging: Writing clean code and debugging techniques."
    ]
  },
  'backend' : {
    'java' : [
      "Java Basics: Set up your environment, write and run basic Java programs, and understand syntax, data types, and control structures.",
      "OOP Concepts: Create and manage classes and objects, utilize inheritance, polymorphism, abstraction, and encapsulation.",
      "Error Handling: Use exception handling, debug applications, and manage resources effectively.",
      "Collections Framework: Work with Lists, Sets, and Maps, and perform operations on collections.",
      "File I/O: Read from and write to files, and implement serialization.",
      "Multithreading: Create and manage threads, and understand concurrency utilities.",
      "Networking and Databases: Build networked applications and connect to databases using JDBC.",
      "Best Practices: Write clean code, apply design patterns, and follow best practices.",
      "Project Development: Plan, implement, and deploy a comprehensive Java project, and showcase your skills."
    ],
    'springboot' : [
      "Set up a Spring Boot application and understand its core components.",
      "Build robust and scalable RESTful web services.",
      "Use Spring Data JPA for data access and database operations.",
      "Secure your applications using Spring Security.",
      "Manage application configurations and deploy your application.",
      "Ensure high performance and test applications effectively."
    ],
    'nodejs' : [
      "Understand Node.js Basics: Learn the fundamentals of Node.js, its architecture, and how to set up a development environment.",
      "Explore Core Modules and NPM: Get familiar with core Node.js modules and manage packages using NPM.",
      "Master Asynchronous Programming: Implement asynchronous techniques using callbacks, promises, and async/await, and handle errors.",
      "Develop with Express.js: Build web servers and RESTful APIs with Express.js, manage databases using MongoDB and Mongoose.",
      "Implement Authentication: Secure applications with authentication strategies, Passport.js, and JWT.",
      "Create Real-Time Features: Use WebSocket to add real-time communication capabilities.",
      "Test and Debug: Perform unit and integration testing, and use debugging tools effectively.",
      "Optimize and Deploy: Optimize performance, deploy applications to cloud platforms, and manage configurations.",
      "Apply Best Practices: Follow coding standards, maintain code quality with linters, and refactor code.",
      "Complete a Project: Develop, test, and deploy a comprehensive Node.js project, demonstrating all learned skills."
    ]         
  },
  'database' : {
    'mysql' : [
      "Understand the fundamentals of relational databases and MySQL architecture.",
      "Master SQL queries for data retrieval, manipulation, and transaction management.",
      "Design and normalize databases for optimal structure and efficiency.",
      "Implement advanced features such as stored procedures, triggers, and views.",
      "Optimize and secure databases for performance, reliability, and data integrity.",
      "Learn best practices for MySQL database administration and backup strategies."
    ],
    'mongodb' : [
      "Understand the fundamentals of MongoDB and NoSQL databases.",
      "Master CRUD operations and basic querying in MongoDB.",
      "Design and optimize MongoDB schemas for performance and scalability.",
      "Implement complex queries and aggregation pipelines.",
      "Learn how to use MongoDB replication and sharding for high availability.",
      "Secure MongoDB deployments and manage user roles and permissions.",
      "Perform backup, recovery, and performance tuning for MongoDB.",
      "Work with advanced MongoDB features like geospatial queries, full-text search, and transactions."
    ]
  },
  'ui_ux' : {
    'figma' : [
      "Understand the fundamentals of Figma and its design capabilities.",
      "Learn to navigate the Figma interface and set up a design workspace.",
      "Master Figma’s core design tools, including shapes, text, and pen tool.",
      "Develop skills in working with layers, groups, frames, and layouts.",
      "Create and manage reusable components and design systems.",
      "Design effective user interfaces and interactive elements.",
      "Build and test interactive prototypes with transitions and animations.",
      "Conduct usability testing and iterate on design based on feedback.",
      "Apply advanced design techniques, including creating design systems and patterns.",
      "Collaborate effectively with team members and manage feedback in Figma.",
      "Integrate Figma with other tools and export assets for development.",
      "Plan, structure, and implement a comprehensive design project.",
      "Present and review final design projects, discussing key learnings and future improvements."
    ],
    'html' : [
      "Understand the fundamentals of HTML and its role in web development.",
      "Learn the structure of HTML documents, including essential tags and elements.",
      "Master the use of basic HTML elements and attributes for content creation and formatting.",
      "Implement advanced HTML5 features, including semantic elements and multimedia integration.",
      "Design and develop responsive web pages that adapt to various screen sizes and devices.",
      "Apply best practices for web accessibility to ensure content is usable for all users.",
      "Optimize HTML code for performance and maintainability.",
      "Plan, implement, and present a comprehensive HTML project incorporating all learned concepts."
    ],
    'css' : [
      "Understand the fundamentals of CSS: Learn the basics of CSS, including syntax, selectors, and properties.",
      "Master styling and layout techniques: Apply advanced styling techniques and layout methods to create visually engaging designs.",
      "Implement responsive design: Design and develop responsive web pages that adapt to different screen sizes and devices.",
      "Explore CSS preprocessors: Learn about preprocessors like Sass or LESS to streamline CSS development.",
      "Ensure accessibility and performance: Apply best practices to ensure designs are accessible and optimized for performance."
    ]
    },
    'others' : {
      'dsa' : [
        "Understand fundamental data structures and their operations.",
        "Master algorithmic techniques for solving complex problems.",
        "Analyze the efficiency of algorithms using Big O notation.",
        "Implement and apply advanced data structures in real-world scenarios.",
        "Optimize algorithms for performance and scalability.",
        "Develop problem-solving skills through algorithm design and coding exercises."
      ],
      'asd' : [
        "Understand fundamental system design principles and concepts.",
        "Learn to design scalable and efficient systems using architectural patterns.",
        "Master techniques for designing and implementing distributed systems.",
        "Analyze system requirements and design solutions that meet performance and reliability goals.",
        "Develop problem-solving skills through real-world system design exercises.",
        "Apply best practices for system scalability, fault tolerance, and data consistency."
      ],
      'aip' : [
        "Understand the job interview process and types of interviews.",
        "Prepare effectively for different types of interviews, including technical and behavioral.",
        "Master strategies for answering common and challenging interview questions.",
        "Develop strong communication and presentation skills for interviews.",
        "Practice mock interviews to simulate real-world interview scenarios.",
        "Receive feedback and learn from interview performance to improve future interviews."
      ]
    }
  };



  return (
    <Section>
      <IconAndText>
        <Icon
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3f22c4dc1218210e1ad5123a830165198fa7baf8c6eb6b3f5b087bdf78cbf4de?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Course Icon"
        />
        <Title>Course Objectives</Title>
      </IconAndText>
      <List>
        {objectives[type][course].map((objective, index) => (
          <ObjectiveItem key={index} text={objective} />
        ))}
      </List>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left:106px;
`;

const IconAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 28px;
`;

const Title = styled.h2`
  font-size: 30px;
  color: var(--Gray-900, #101828);
  font-weight: 600;
  margin-top: 24px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
  padding-left: 16px;
`;

export default CourseObjectives;
