/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const ImageComponent = () => {
  return (
    <StyledImage
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a15740a4d1c29e647723834a21213a60c64b47bd6bab767855b21bbae10bf189?apiKey=a6dad463439244ae86e3212633b4369a&"
      alt="Decorative image"
    />
  );
};

const StyledImage = styled.img`
  aspect-ratio: 0.91;
  object-fit: auto;
  object-position: center;
  width: 100%;
  border-radius: 6px;
  flex-grow: 1;

  @media (max-width: 991px) {
    margin-top: 19px;
    width: 143%;
    position: relative!important;
    top: 0px!important;
    right: 48px;
  }
`;

export default ImageComponent;
