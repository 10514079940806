import React, { useRef, useEffect } from "react";

const Policy = () => {
    return (
    <div className="container">
        <h1>Privacy Policy</h1>

        <p>("we", "us", or "our") is committed to protecting the privacy of our students, staff, and website visitors. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to safeguard your personal data.</p>

        <h2>1. Information We Collect</h2>
        <p>We collect different types of information to provide and improve our services, including:</p>
        <ul>
            <li><strong>Personal Information</strong>: Name, contact details (email, phone number, address), date of birth, education qualifications, employment details, and any other information you provide when you enroll or interact with us.</li>
            <li><strong>Usage Data</strong>: Information collected automatically when you visit our website, such as IP address, browser type, device information, and pages visited.</li>
            <li><strong>Financial Data</strong>: Payment details (if applicable), including billing information when you register for paid courses or services.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We may use the information collected for various purposes, including:</p>
        <ul>
            <li><strong>Service Provision</strong>: To manage enrollments, offer courses, provide certification, and other related services.</li>
            <li><strong>Communication</strong>: To send notifications regarding course updates, schedule changes, or promotional materials relevant to you.</li>
            <li><strong>Improvement of Services</strong>: To analyze usage patterns and feedback for improving our curriculum, website, and services.</li>
            <li><strong>Compliance</strong>: To comply with legal obligations, resolve disputes, and enforce agreements.</li>
        </ul>

        <h2>3. Data Sharing</h2>
        <p>We do not sell or rent your personal data. However, we may share your information with:</p>
        <ul>
            <li><strong>Service Providers</strong>: Third-party vendors that assist in delivering our services (e.g., payment processors, technical support).</li>
            <li><strong>Legal Obligations</strong>: When required by law or to protect the rights and safety of our staff, students, or the public.</li>
            <li><strong>With Your Consent</strong>: In cases where we explicitly seek your consent to share data for a specific purpose.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p>We take the security of your personal information seriously. We implement industry-standard measures such as encryption, secure servers, and access controls to protect your data. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

        <h2>5. Retention of Information</h2>
        <p>We will retain your personal information only for as long as necessary to fulfill the purposes for which it was collected or as required by law.</p>

        <h2>6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
            <li><strong>Access</strong>: Request access to the personal information we hold about you.</li>
            <li><strong>Correction</strong>: Request correction of inaccurate or incomplete data.</li>
            <li><strong>Deletion</strong>: Request deletion of your data, subject to legal requirements.</li>
            <li><strong>Opt-out</strong>: Unsubscribe from marketing communications or other non-essential notifications.</li>
        </ul>
        <p>To exercise these rights, please contact us at [Insert Contact Email].</p>

        <h2>7. Children’s Privacy</h2>
        <p>Our services are not intended for individuals under the age of 16. We do not knowingly collect personal information from children. If you believe that a child has provided us with personal data, please contact us to have it removed.</p>

        <h2>8. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites.</p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We encourage you to review it periodically to stay informed of how we are protecting your information. Changes will be effective immediately upon posting on this page.</p>

        <h2>10. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>
            <strong>Sodhark Technologies</strong> <br />
            Email: info@sodhark.com <br />
            Phone: +91-141-6657675 <br />
            Address: 237, Giriraj Nagar, Muhana Mandi Road, Manasarovar, Jaipur, Raj - 302020
        </p>
        <br/>
        <br/>
    </div>
    );
};

export default Policy;
