/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import EventSection from "./EventSection";
import CareerCounselling from "./CareerCounselling";
import Footer from "../CommonComponents/Footer/Footer";

const EventsPage = () => {
  return (
    <StyledEventsPage>
      <MainContainer>
        <Header />
        <EventSection />
        <CareerCounselling />
      </MainContainer>
      <Footer style={{paddingBottom:'35px'}}/>
    </StyledEventsPage>
  );
};

const StyledEventsPage = styled.div`
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MainContainer = styled.div`
  align-items:center;
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 35px;
`;

export default EventsPage;
