/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import EventCard from "./EventCard";

const upcomingEvents = [
  {
    date: "August 25, 2024 - 5:30 pm",
    title: "React JS",
    description:
      "This React JS online training will also help you master the fundamentals of React, an important web framework for developing user interfaces. It covers topics such as JSX, props, state, events, reducers, actions, and the state tree. Join Today!",
    speaker: {
      name: "Sonu Dhakar",
      role: "Tech Lead",
    },
    attendees: 23,
    status: "open",
    seatsLeft: 5,
  },
  {
    date: "August 25, 2024 - 5:30 pm",
    title: "React JS",
    description:
      "This React JS online training will also help you master the fundamentals of React, an important web framework for developing user interfaces. It covers topics such as JSX, props, state, events, reducers, actions, and the state tree. Join Today!",
    speaker: {
      name: "Sonu Dhakar",
      role: "Tech Lead",
    },
    attendees: 23,
    status: "enrolled",
  },
  {
    date: "August 25, 2024 - 5:30 pm",
    title: "React JS",
    description:
      "This React JS online training will also help you master the fundamentals of React, an important web framework for developing user interfaces. It covers topics such as JSX, props, state, events, reducers, actions, and the state tree. Join Today!",
    speaker: {
      name: "Sonu Dhakar",
      role: "Tech Lead",
    },
    attendees: 23,
    status: "open",
  },
];

const pastEvents = [
  {
    date: "August 25, 2024 - 5:30 pm",
    title: "React JS",
    description:
      "This React JS online training will also help you master the fundamentals of React, an important web framework for developing user interfaces. It covers topics such as JSX, props, state, events, reducers, actions, and the state tree. Join Today!",
    speaker: {
      name: "Sonu Dhakar",
      role: "Tech Lead",
    },
    attendees: 23,
    status: "past",
  },
  {
    date: "August 25, 2024 - 5:30 pm",
    title: "React JS",
    description:
      "This React JS online training will also help you master the fundamentals of React, an important web framework for developing user interfaces. It covers topics such as JSX, props, state, events, reducers, actions, and the state tree. Join Today!",
    speaker: {
      name: "Sonu Dhakar",
      role: "Tech Lead",
    },
    attendees: 23,
    status: "past",
  },
  {
    date: "August 25, 2024 - 5:30 pm",
    title: "React JS",
    description:
      "This React JS online training will also help you master the fundamentals of React, an important web framework for developing user interfaces. It covers topics such as JSX, props, state, events, reducers, actions, and the state tree. Join Today!",
    speaker: {
      name: "Sonu Dhakar",
      role: "Tech Lead",
    },
    attendees: 23,
    status: "past",
  },
];

const EventSection = () => {
  return (
    <StyledEventSection>
      <SectionHeading>Events</SectionHeading>
      <Title>Tech Connect 🎉</Title>
      <Subtitle>Free Online & Offline Events to Elevate Your Skills</Subtitle>
      <UpcomingEventsHeading>Upcoming</UpcomingEventsHeading>
      <EventList>
        {upcomingEvents.map((event, index) => (
          <EventCard key={index} {...event} />
        ))}
      </EventList>
      <PastEventsHeader>
        <PastEventsHeading>Past Events</PastEventsHeading>
        <Divider />
      </PastEventsHeader>
      <EventList>
        {pastEvents.map((event, index) => (
          <EventCard key={index} {...event} />
        ))}
      </EventList>
    </StyledEventSection>
  );
};

const StyledEventSection = styled.section`
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 1215px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SectionHeading = styled.h2`
  color: #7f56d9;
  text-align: center;
  margin-top: 64px;
  font: 600 16px Inter, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Title = styled.h1`
  color: #344054;
  text-align: center;
  margin-top: 9px;
  font: 500 34px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const Subtitle = styled.p`
  color: #344054;
  margin-top: 16px;
  font: 500 16px/1 Inter, sans-serif;
`;

const UpcomingEventsHeading = styled.h3`
  color: #072c50;
  align-self: start;
  margin: 64px 0 0 101px;
  font: 500 20px/1.2 Inter, sans-serif;
  @media (max-width: 991px) {
    margin: 40px 0 0 10px;
  }
`;

const EventList = styled.div`
  width: 100%;
  max-width: 1063px;
  margin: 32px 0 0 24px;
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

const PastEventsHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: 1056px;
  gap: 14px;
  color: #072c50;
  flex-wrap: wrap;
  margin: 52px 0 0 22px;
  font: 500 20px/1.2 Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const PastEventsHeading = styled.h3`
  flex-grow: 1;
`;

const Divider = styled.div`
  background-color: rgba(0, 0, 0, 0.09);
  width: 929px;
  max-width: 100%;
  height: 2px;
  margin: auto 0;
`;

export default EventSection;
