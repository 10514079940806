/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import styled from "styled-components";
import {init,saveCouncelling} from '../../utility';

function ContactForm() {

  const [councelling,setCouncelling] = useState({email : '',mobile_number:''});
  init();
  return (
    <FormWrapper>
      <FormContent>
        <FormHeader>
          <Title>Free Career Counselling</Title>
        </FormHeader>
        <FormFields>
          <InputGroup>
            <Label htmlFor="phone">Phone</Label>
            <Input id="phone" type="tel" placeholder="+91 Phone number" defaultValue={councelling.mobile_number} onKeyUp={(event)=>{
              let councellingObj = Object.assign({},councelling);
              councellingObj.mobile_number = event.target.value.trim();
              setCouncelling(councellingObj);
            }}/>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="email">Email</Label>
            <Input id="email" type="email" placeholder="Enter your email" defaultValue={councelling.email} onKeyUp={(event)=>{
              let councellingObj = Object.assign({},councelling);
              councellingObj.email = event.target.value.trim();
              setCouncelling(councellingObj);
            }} />
          </InputGroup>
        </FormFields>
      </FormContent>
      <SubmitSection>
        <Logo
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f5ffc34d4431790484c5fd8575d282d0f90c6177cad03c80ab4cc789d61cf76?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Company Logo"
        />
        <SubmitButton onClick={(event)=>{
          event.preventDefault();
          event.stopPropagation();
          saveCouncelling(councelling).then(()=>{
            alert("Thanks, we will get back to you!");
            setCouncelling({email : '', mobile_number : ''});
          }).catch(()=>{
            alert("Sorry, could not able to process your request!");
          })
        }}>Submit</SubmitButton>
      </SubmitSection>
    </FormWrapper>
  );
}

const FormWrapper = styled.form`
  border-radius: 7px;
  align-self: center;
  margin-top: 37px;
  width: 996px;
  max-width: 100%;
  padding: 0 42px 65px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding: 0 20px 65px;
    flex-direction: column;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FormHeader = styled.div`
  margin-top: 32px;
`;

const Title = styled.h2`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  font-family: Inter, sans-serif;
  color:black;
`;

const FormFields = styled.div`
  display: flex;
  margin-top: 7px;
  gap: 26px;
  font-size: 16px;
  color: #667085;
  font-weight: 400;
  flex-wrap: wrap;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.label`
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 8px;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  font-size: 16px;
  color: #667085;
`;

const SubmitSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 28%;
  @media (max-width: 991px) {
    width: 100%;
    align-items: center;
    margin-top: 20px;
  }
`;

const Logo = styled.img`
  aspect-ratio: 1.01;
  object-fit: contain;
  object-position: center;
  width: 161px;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.09);
  max-width: 100%;
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  border: 1px solid #ef4236;
  background: linear-gradient(201deg, #ef4236 2.29%, #faa93f 108.55%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  margin-top: 17px;
  cursor: pointer;
`;

export default ContactForm;
