/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 251px;
`;

const NotFoundImage = styled.img`
  aspect-ratio: 2.27;
  object-fit: contain;
  object-position: center;
  width: 275px;
  max-width: 100%;
`;

const Title = styled.h1`
  color: rgba(7, 44, 80, 0.5);
  font-size: 24px;
  margin-top: 34px;
`;

const HomeLink = styled.a`
  display: flex;
  margin-top: 78px;
  gap: 12px;
  font-size: 20px;
  color: #7f56d9;
  text-decoration: none;
  align-items: center;
`;

const ArrowIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

const NotFoundContent = () => {
  return (
    <ContentWrapper>
      <NotFoundImage
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/30f0d836aed21b239111e981bd6985bc47964350fdf399b5183522120846afd9?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="404 illustration"
      />
      <Title>Page not found</Title>
      <HomeLink href="/">
        Go to home page
        <ArrowIcon
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/230a428249f6354416e1730729755eb9a9c22c1e6c4f89f082061854a0a30db8?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt=""
        />
      </HomeLink>
    </ContentWrapper>
  );
};

export default NotFoundContent;
