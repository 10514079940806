/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

function TeamMember({ name, role, description, image, socialIcon }) {
  return (
    <MemberCard>
      <MemberImage src={image} alt={name} />
      <MemberInfo>
        <Name>{name}</Name>
        <Role>{role}</Role>
        <Description>{description}</Description>
        <SocialIcon src={socialIcon} alt="Social Media" />
      </MemberInfo>
    </MemberCard>
  );
}

const MemberCard = styled.article`
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 14px 24px rgba(7, 44, 80, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 24px 24px 44px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const MemberImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 80px;
  border-radius: 40px;
  align-self: center;
  min-height: 80px;
`;

const MemberInfo = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Inter, sans-serif;
`;

const Name = styled.h3`
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
`;

const Role = styled.p`
  color: #6941c6;
  font-size: 16px;
  font-weight: 400;
`;

const Description = styled.p`
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
`;

const SocialIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  margin-top: 16px;
`;

export default TeamMember;
