/**
 * This code was generated by Builder.io.
 */
import React, { useRef, useEffect } from "react";
import brouchureFile from '../brouchure.pdf';

const Brouchure = () => {

    const link_brouchure = useRef(null);

    useEffect(() => {
        link_brouchure.current.click();
    }, []);

    return (
        <a href={brouchureFile} ref={link_brouchure} rel="noreferrer" style={{color:"black"}}>
                        Please Wait...
        </a>
    );
};

export default Brouchure;
