/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <StyledHeader>
      <Logo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d0b31c9c11d8a4155565d03ac9923a7e50bc37b47a9fe7e7f36931714cb8427?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Logo"
      />
      <Nav>
          <NavItem href="/">Home</NavItem>
          <NavItem href="/about">About Us</NavItem>
          <NavItem href="/technologies">Technologies</NavItem>
          <NavItem href="/programmes">Programmes</NavItem>
          <NavItem href="/courses">Courses</NavItem>
          <NavItem href="/contactus">Contact Us</NavItem>
      </Nav>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  width: 100%;
  max-width: 1215px;
  gap: 20px;
  color: #072c50;
  white-space: nowrap;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font: 500 18px Inter, sans-serif;
  align-self: center;

  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Logo = styled.img`
  aspect-ratio: 4.02;
  object-fit: contain;
  object-position: center;
  width: 205px;
  border-radius: 0;
  max-width: 100%;
`;

const Nav = styled.nav`
  align-self: start;
  display: flex;
  margin-top: 7px;
  gap: 40px 44px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const NavItem = styled.a`
  flex-grow: 1;
  cursor: pointer;
  color:black;
  font-size:14px;
  text-decoration:none;
`;

export default Header;
