/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const NavigationItem = ({ text, link}) => {
  return <NavItem href={link}>{text}</NavItem>;
};

const NavItem = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
`;

export default NavigationItem;
