/**
 * This code was generated by Builder.io.
 */
import React, {useState} from "react";
import {
  FormContainer,
  FlexContainer,
  Column,
  RightColumn,
  ColumnContent,
  HeaderContainer,
  HeaderText,
  InputFieldContainer,
} from "./StyledComponents/Containers";
import {
  MainHeading,
  SubHeading,
  SecondaryLabel,
} from "./StyledComponents/HeaderTextElements";
import {
  InputField,
  InputBase,
  InputLabelWrapper,
  InputWrapper,
  InputContent,
} from "./StyledComponents/InputFieldComponents";
import {
  ImageContainer,
  StyledImage,
  StyledButton,
} from "./StyledComponents/ImageAndButtonComponents";

import {init,saveCouncelling} from '../../utility';

function FormComponent() {
  const [councelling,setCouncelling] = useState({email : '',mobile_number:''});
  init();
  return (
    <FormContainer>
      <FlexContainer>
        <Column>
          <ColumnContent>
            <HeaderContainer>
              <HeaderText>
                <MainHeading>Free Career Counselling</MainHeading>
                <SubHeading>Email</SubHeading>
              </HeaderText>
              <SecondaryLabel>Email</SecondaryLabel>
            </HeaderContainer>
            <InputFieldContainer>
              <InputField>
                <InputBase>
                  <InputLabelWrapper>
                    <label htmlFor="phoneInput" className="sr-only">
                      Phone number
                    </label>
                    <InputWrapper>
                      <InputContent
                        type="text"
                        id="phoneInput"
                        placeholder="+91 Phone number"
                        defaultValue={councelling.mobile_number} onKeyUp={(event)=>{
                          let councellingObj = Object.assign({},councelling);
                          councellingObj.mobile_number = event.target.value.trim();
                          setCouncelling(councellingObj);
                        }}
                      />
                    </InputWrapper>
                  </InputLabelWrapper>
                </InputBase>
              </InputField>
              <form>
                <label htmlFor="emailInput" className="sr-only">
                  Enter your email
                </label>
                <InputWrapper>
                  <InputContent
                    type="email"
                    id="emailInput"
                    placeholder="Enter your email"
                    defaultValue={councelling.email} onKeyUp={(event)=>{
                      let councellingObj = Object.assign({},councelling);
                      councellingObj.email = event.target.value.trim();
                      setCouncelling(councellingObj);
                    }}
                  />
                </InputWrapper>
              </form>
            </InputFieldContainer>
          </ColumnContent>
        </Column>
        <RightColumn>
          <ImageContainer>
            <StyledImage
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4dbea3abde4a68398ed4ec16b8763d4566f26fc4c9912c356d0639c3a566ec3e?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            />
            <StyledButton onClick={(event)=>{
          event.preventDefault();
          event.stopPropagation();
          saveCouncelling(councelling).then(()=>{
            alert("Thanks, we will get back to you!");
            setCouncelling({email : '', mobile_number : ''});
          }).catch(()=>{
            alert("Sorry, could not able to process your request!");
          })
        }}>Submit</StyledButton>
          </ImageContainer>
        </RightColumn>
      </FlexContainer>
    </FormContainer>
  );
}

export default FormComponent;
