/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

function DeliveryFormat() {
  return (
    <FormatWrapper>
      <IconWrapper>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb07160a873b4a177b4a7cd5758edab2472d26798cbb3919f50bf2cbbbdf719?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Delivery Format Icon"
        />
      </IconWrapper>
      <FormatContent>
        <h2 className="format-title">Delivery Format</h2>
        <p className="format-description">
          The course will be delivered through a combination of video lectures,
          practical coding exercises, quizzes, and hands-on projects.
          <br />
          Students will have access to a dedicated online learning platform
          where they can access course materials, submit assignments, and
          interact with instructors and fellow students.
          <br />
          Weekly live Q&A sessions will be held to address any questions or
          concerns students may have.
        </p>
      </FormatContent>
    </FormatWrapper>
  );
}

const FormatWrapper = styled.section`
  display: flex;
  margin-top: 47px;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const IconWrapper = styled.div`
  background-color: #f1ecfb;
  border-radius: 50%;
  align-self: center;
  z-index: 10;
  display: flex;
  width: 71px;
  height: 71px;
  padding-bottom: 7px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    margin-top: -22px;
    @media (max-width: 991px) {
      margin-right: -15px;
    }
  }
`;

const FormatContent = styled.div`
  border-radius: 7px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px 21px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  margin-top: -35px;
  @media (max-width: 991px) {
    padding: 20px;
  }

  .format-title {
    color: #000;
    text-align: center;
    align-self: center;
    font: 500 20px "Encode Sans Semi Condensed", sans-serif;
    margin-bottom: 20px;
  }

  .format-description {
    color: #101828;
    font: 400 16px/25px Inter, sans-serif;
  }
`;

export default DeliveryFormat;
