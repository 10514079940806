/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const CourseTabs = ({type,courseTypeClickHandler}) => (
  <TabsContainer>
    {
          type == "frontend" ? <TabButton active onClick={()=>courseTypeClickHandler('frontend')}>
              <TabContent>Frontend</TabContent>
               <TabBorder colored />
            </TabButton> 
          : <TabButton onClick={()=>courseTypeClickHandler('frontend')}>
            <TabContent>Frontend</TabContent>
            <TabBorder />
          </TabButton> 
    }
    {
          type == "backend" ? <TabButton active onClick={()=>courseTypeClickHandler('backend')}>
              <TabContent>Backend</TabContent>
               <TabBorder colored />
            </TabButton> 
          : <TabButton onClick={()=>courseTypeClickHandler('backend')}>
            <TabContent>Backend</TabContent>
            <TabBorder />
          </TabButton> 
    }
    {
          type == "database" ? <TabButton active onClick={()=>courseTypeClickHandler('database')}>
              <TabContent>Database</TabContent>
               <TabBorder colored />
            </TabButton> 
          : <TabButton onClick={()=>courseTypeClickHandler('database')}>
            <TabContent>Database</TabContent>
            <TabBorder />
          </TabButton> 
    }
    {
          type == "ui_ux" ? <TabButton active onClick={()=>courseTypeClickHandler('ui_ux')}>
              <TabContent>UI/UX</TabContent>
               <TabBorder colored />
            </TabButton> 
          : <TabButton onClick={()=>courseTypeClickHandler('ui_ux')}>
            <TabContent>UI/UX</TabContent>
            <TabBorder />
          </TabButton> 
    }
    {
          type == "others" ? <TabButton active onClick={()=>courseTypeClickHandler('others')}>
              <TabContent>Others</TabContent>
               <TabBorder colored />
            </TabButton> 
          : <TabButton onClick={()=>courseTypeClickHandler('others')}>
            <TabContent>Others</TabContent>
            <TabBorder />
          </TabButton> 
    }
  </TabsContainer>
);

const TabsContainer = styled.nav`
  display: flex;
  gap: 16px;
  color: var(--Gray-500, #667085);
  margin: 23px 0 0 101px;
  font: 600 16px/1 Inter, sans-serif;
`;

const TabButton = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ active }) => (active ? "rgba(239, 66, 54, 1)" : "inherit")};
  align-items: center;
  width: auto;
  cursor:pointer;
`;

const TabContent = styled.div`
  padding-bottom: 16px;
`;

const TabBorder = styled.div`
  background-color: ${({ colored }) =>
    colored ? "rgba(239, 66, 54, 1)" : "transparent"};
  height: 2px;
  width: 100%;
`;

export default CourseTabs;
