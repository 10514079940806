/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const Breadcrumb = ({type,course}) => {

  const types = {
    "frontend" : "Frontend",
    "backend"  : "Backend",
    "database" : "Database",
    "ui_ux"    : "UI/UX",
    "others"   : "Others"
  }

  return (
    <StyledBreadcrumb>
      <span>{types[type]}</span>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3fbd5d94092e2544a6b65a08ece3850d5c4c2bd36a816e632c93565ce02bce6e?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
        alt="Separator"
      />
    </StyledBreadcrumb>
  );
};

const StyledBreadcrumb = styled.nav`
  display: flex;
  align-items: center;
  color: #344054;
  white-space: nowrap;
  margin: 50px 0 0 102px;
  font: 400 14px Inter, sans-serif;

  img {
    aspect-ratio: 1;
    object-fit: contain;
    width: 16px;
    margin: 0 4px;
  }

  .current {
    color: rgba(52, 64, 84, 0.58);
  }

  @media (max-width: 991px) {
    white-space: initial;
    margin: 40px 0 0 10px;
  }
`;

export default Breadcrumb;
