/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

function ValueCard({ icon, title, description }) {
  return (
    <CardWrapper>
      <Icon src={icon} alt={title} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </CardWrapper>
  );
}

const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.33% - 21.33px);
  min-width: 240px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
`;

const Title = styled.h3`
  color: #101828;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
`;

const Description = styled.p`
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  text-align: center;
`;

export default ValueCard;
