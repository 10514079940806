/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const DeliveryFormat = () => {
  return (
    <StyledDeliveryFormat>
      <div className="icon-wrapper">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb07160a873b4a177b4a7cd5758edab2472d26798cbb3919f50bf2cbbbdf719?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Delivery format icon"
        />
      </div>
      <h3>Delivery Format</h3>
      <p>
        The course will be delivered through a combination of video lectures,
        practical coding exercises, quizzes, and hands-on projects.
        <br />
        <br />
        Students will have access to a dedicated online learning platform where
        they can access course materials, submit assignments, and interact with
        instructors and fellow students.
        <br />
        <br />
        Weekly live Q&A sessions will be held to address any questions or
        concerns students may have.
      </p>
    </StyledDeliveryFormat>
  );
};

const StyledDeliveryFormat = styled.aside`
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  padding: 30px 20px;

  .icon-wrapper {
    background-color: #f1ecfb;
    border-radius: 50%;
    width: 71px;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -56px auto 20px;

    img {
      width: 100%;
      height: auto;
      margin-top: -22px;
    }
  }

  h3 {
    color: #000;
    text-align: center;
    font: 500 20px "Encode Sans Semi Condensed", sans-serif;
    margin-bottom: 20px;
  }

  p {
    color: #101828;
    font: 400 16px/25px Inter, sans-serif;
  }

  @media (max-width: 991px) {
    .icon-wrapper img {
      margin-right: -15px;
    }
  }
`;

export default DeliveryFormat;
