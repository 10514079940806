/**
 * This code was generated by Builder.io.
 */
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Inter, sans-serif;
  }
  .not-found-page {
    background-color: #f9fafb;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    font-weight: 600;
    padding: 35px 80px 341px;
  }
  @media (max-width: 991px) {
    .not-found-page {
      padding: 0 20px 100px;
    }
  }
`;
