/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const CourseCard = ({
  imageSrc,
  title,
  learners,
  averageRating,
  reviewsCount,
  description,
  courseStartDate,
  states,
  image,
  name,
  type
}) => (
  <CourseCardContainer>
    <CourseHeader>
      <CourseTitle>{title}</CourseTitle>
      <CourseImage loading="lazy" src={imageSrc} alt={title} />
    </CourseHeader>
    {/* <CourseInfo>
      <LearnersCount>
        <LearnersIcon
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/92d6ee79ab0e59090966d4d50558633fb265f05138614d1a872e1042ee4b3059?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Learners Icon"
        />
        {learners} <span className="gray-text">learners</span>
      </LearnersCount>
      <Divider />
      <Rating>
        <RatingIcon
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/14cceaecfd5676f7542eb0bd2eb55a33c4d1357cad487783647bb57f968c7845?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
          alt="Rating Icon"
        />
        <RatingValue>{averageRating}</RatingValue>
        <ReviewsCount>({reviewsCount} reviews)</ReviewsCount>
      </Rating>
    </CourseInfo> */}
    <CourseContent>
      <Column>
        <ContentImageWrapper>
          <BackgroundImage
            loading="lazy"
            src={image}
            alt=""
          />
          {/* <OverlayImage
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6eafdc77eb3ccdd75556873b65cd0a146da463074e7586f6bd91d66e0205dcb4?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            alt=""
          /> */}
        </ContentImageWrapper>
      </Column>
      <CourseDescription>
        {description}
        {/* <CourseStartDate>
          <ClockIcon
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ea89a4cc63b24c68428707c42bdf57259eb1e305e8e0541d83ab1ce74ef8ff54?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a"
            alt=""
          />
          <CourseDate>
            <span className="gray-text">Online classes start on</span>{" "}
            <span className="blue-text">{courseStartDate}</span>
          </CourseDate>
        </CourseStartDate> */}
        <CourseActions>
          <ActionButton onClick={()=>{window.location.href = `/course?type=${type}&id=${name}`}} primary>Know More</ActionButton>
          {/* <ActionButton>Download Curriculum</ActionButton> */}
        </CourseActions>
      </CourseDescription>
    </CourseContent>
  </CourseCardContainer>
);

const CourseCardContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  max-width: 100%;
  width: 965px;
`;

const CourseHeader = styled.div`
  display: flex;
  gap: 12px;
  margin-left: 24px;
  margin-top: 45px;
  white-space: nowrap;
  position: relative;
  top: 42px;
`;

const CourseTitle = styled.h2`
  color: rgba(52, 64, 84, 1);
  font: 500 20px Encode Sans Semi Condensed, -apple-system, Roboto, Helvetica,
    sans-serif;
`;

const CourseImage = styled.img`
  aspect-ratio: 1;
  width: 24px;
  position:relative;
  bottom:2px;
`;

const CourseInfo = styled.div`
  display: flex;
  gap: 14px;
  font-family: Inter, sans-serif;
  margin-left: 102px;
  margin-top: 18px;
`;

const LearnersCount = styled.div`
  display: flex;
  gap: 11px;
  align-items: center;
  font-size: 15px;
  color: rgba(127, 86, 217, 1);
  font-weight: 500;
`;

const LearnersIcon = styled.img`
  aspect-ratio: 1;
  width: 21px;
`;

const Divider = styled.div`
  background-color: rgba(52, 64, 84, 0.48);
  height: 26px;
  width: 1px;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const RatingIcon = styled.img`
  aspect-ratio: 1;
  width: 18px;
`;

const RatingValue = styled.div`
  color: rgba(127, 86, 217, 1);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
`;

const ReviewsCount = styled.div`
  color: rgba(52, 64, 84, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
`;

const CourseContent = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 8px;
  flex-direction: row-reverse;
  align-items: stretch;
  flex-wrap: nowrap;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 42%;
`;

const ContentImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: relative;
  aspect-ratio: 1.63;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 87px 78px;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const OverlayImage = styled.img`
  aspect-ratio: 1;
  width: 65px;
  backdrop-filter: blur(8px);
`;

const CourseDescription = styled.div`
  display: flex;
  flex-direction: column;
  font: 400 16px Inter, sans-serif;
  margin-left: 20px;
  width: 58%;
  position: relative;
  top: 52px;

  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
`;

const CourseStartDate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 58px;
  gap: 6px;
  color: rgba(7, 44, 80, 1);

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ClockIcon = styled.img`
  aspect-ratio: 1;
  width: 24px;
`;

const CourseDate = styled.div``;

const CourseActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
  margin-top: 74px;
  font-weight: 600;
`;

const ActionButton = styled.div`
  align-self: stretch;
  border: 1px solid
    ${({ primary }) =>
      primary ? "var(--Brand-600, #7f56d9)" : "var(--Gray-300, #d0d5dd)"};
  background: ${({ primary }) =>
    primary ? "var(--Brand-600, #7f56d9)" : "var(--White, #fff)"};
  color: ${({ primary }) =>
    primary ? "var(--White, #fff)" : "rgba(0, 0, 0, 1)"};
  border-radius: 8px;
  min-width: 240px;
  padding: 10px 18px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
`;

export default CourseCard;
