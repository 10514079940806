/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const features = [
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/d5d0569dd0c1a109bef6a399274ff75a222f1424a61ebc34405d90ea3ef5a8f3?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    imgAlt: "Hands-on Experience",
    title: "Hands-on Experience",
    description:
      "Gain practical skills through interactive projects and simulations, ensuring you’re job-ready.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/6ea34b34a0a55e002b8d4e2f5822b7743f675b1583108694b2333bb7e9313fa3?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    imgAlt: "Expert Mentors",
    title: "Expert Mentors",
    description:
      "Learn from seasoned IT professionals who provide personalized guidance and industry insights.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/39321e3cbeb729f1d1d05e5dad0cdc961fb0ae04dd5587e030cd1909bd7a8d6b?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    imgAlt: "Comprehensive Materials",
    title: "Comprehensive Materials",
    description:
      "Access high-quality resources, including video tutorials catering to diverse learning styles.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/eda3f927a68b440efb30c6cc11e8b86f17ecebfd3fe6fe551f73bd2e7e359ee7?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    imgAlt: "Job-Ready Skills",
    title: "Job-Ready Skills",
    description:
      "Our courses align with current market demands, focusing on in-demand skills and tools.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/d6fbb6e4bba24fcb33922a2053dd7ac5477707e27b1d9adbf837a039da4b460c?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    imgAlt: "Community Support",
    title: "Community Support",
    description:
      "Join a vibrant learning community with study groups, forums, and webinars to enhance your experience.",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ce8af7eb16e6bd557a7b8e7b4d993c6eb3b31d9c733ae93e0ff86ed053ca4652?apiKey=a6dad463439244ae86e3212633b4369a&&apiKey=a6dad463439244ae86e3212633b4369a",
    imgAlt: "Foundation for Future Technologies",
    title: "Foundation for Future Technologies",
    description:
      "Benefit from resume building, interview preparation, and job placement support to secure your dream job.",
  },
];

const FeaturesList = () => (
  <Content>
    {features.map((feature, index) => (
      <Feature key={index}>
        <FeatureImage src={feature.imgSrc} alt={feature.imgAlt} />
        <TextBlock>
          <Title>{feature.title}</Title>
          <Description>{feature.description}</Description>
        </TextBlock>
      </Feature>
    ))}
  </Content>
);

export default FeaturesList;

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 32px;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 64px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Feature = styled.article`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
`;

const FeatureImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  align-self: center;
`;

const TextBlock = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Title = styled.h2`
  color: var(--Gray-900, #101828);
  font-size: 20px;
  font-weight: 500;
`;

const Description = styled.p`
  color: var(--Gray-600, #475467);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  align-self: center;
  margin-top: 8px;
`;
